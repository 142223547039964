// src/App.js

import React from "react";

// New - import the React Router components, and the Profile page component

import ReactGA from "react-ga";
import IFR from "./ifr";

ReactGA.initialize("UA-166020619-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div
      className="App"
      style={{
        backgroundColor: "#1a2036",
        margin: "0px, -10px,-10px,0px  ",
        padding: "30px",
        color: "white",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      {/* Don't forget to include the history module */}
      <IFR />
    </div>
  );
}

export default App;
