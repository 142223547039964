// src/components/NavBar.js

import React from "react";

const NavBar = ({ props }) => {
  let {
    positive,
    nPos,
    newdeath,
    totaldeath,
    Rifr,
    totalTestResults,
    tests,
    hospitalizedCumulative,
    newHospitalized,
    newVaccinationsCompleted,
    vaccinationsCompleted,
    newVaccinationsDistributed,
    vaccinesDistributed,
    newVaccinationsInitiated,
    vaccinationsInitiated
  } = props.recentDay || {};
  let { RReal, cfr, tCFR, population } = props;
  let userLang = navigator.language || navigator.userLanguage;
  let getStyle = (name) => {
    let color = {
      Cases: "#75b989",
      Deaths: "#a83238",
      CFR: "#ff5f52",
      R: "#ff9900",
      Tests: "#8884d8",
      Hospitalizations: "#9575cd",
      VaccinationsC: "#84ff3f8c",
      VaccinationsI: "#14ff3f8c",
      VaccinationsD: "#54ff3f8c",
    };
    return {
      border: "1px solid #fff",
      fontWeight: "500",
      color: color[name],
      width: "300px",
      height: "80px",
      textDecoration: "none",
      borderRadius: "5px",
      margin: "auto",
    };
  };
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(310px, 1fr))",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <div style={getStyle("Cases")}>
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            Cases
          </div>
          {(positive || 0).toLocaleString(userLang)} (
          {((positive / population) * 100).toLocaleString(userLang)}% of
          population)
          <br />+{(nPos || 0).toLocaleString(userLang)} (
          {((nPos / population) * 100).toLocaleString(userLang)}% of population)
        </div>
        <div
          style={getStyle("Deaths")}
        //to="/"
        >
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            Deaths
          </div>
          {(totaldeath || 0).toLocaleString(userLang)} (
          {((totaldeath / population) * 100).toLocaleString(userLang)}% of
          population)
          <br />+{(newdeath || 0).toLocaleString(userLang)} (
          {((newdeath / population) * 100).toFixed(4)}% of population)
        </div>
        <div style={getStyle("CFR")}>
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            CFR
          </div>
          Total: {(tCFR * 100 || 0).toLocaleString(userLang)}%
          <br />
          Recent: {(cfr * 100 || 0).toLocaleString(userLang)}%
        </div>
        <div
          style={getStyle("R")}
        //to="/"
        >
          <div
            style={{
              paddingTop: "4px",
              paddingBottom: "8px",
              fontWeight: "700",
            }}
          >
            R
          </div>
          Past 30 days: {(RReal || 0).toLocaleString(userLang)}
          <br />
          Today: {((Rifr || 0).toFixed(2) || 0).toLocaleString(userLang)}
        </div>
        {/* { 
        props.country ==='United States' &&  props.county ==='United States' &&
        <div style={getStyle('Tests')}>
          <div
          style={{
          paddingTop: "4px",
          paddingBottom: "8px",
          fontWeight: "700",
        }}
        >
          Tests 
          </div>
          {(totalTestResults || 0).toLocaleString(userLang)} ({(totalTestResults/population*100).toLocaleString(userLang)}% of population)
          <br />
          +{(tests || 0).toLocaleString(userLang)}  ({(tests/population*100).toLocaleString(userLang)}% of population)


        </div>
      } */}
        {props.country === "United States" &&
          (hospitalizedCumulative > 0 || newHospitalized > 0) && (
            <div style={getStyle("Hospitalizations")}>
              <div
                style={{
                  paddingTop: "4px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                }}
              >
                Hospitalizations
              </div>
              {(hospitalizedCumulative || 0).toLocaleString(userLang)} (
              {((hospitalizedCumulative / population) * 100).toLocaleString(
                userLang
              )}
              % of population)
              <br />+{(newHospitalized || 0).toLocaleString(userLang)} (
              {((newHospitalized / population) * 100).toFixed(4)}% of
              population)
            </div>
          )}
        {props.country === "United States" &&
          (tests > 0 || totalTestResults > 0) && (
            <div style={getStyle("Tests")}>
              <div
                style={{
                  paddingTop: "4px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                }}
              >
                Tests
              </div>
              {(totalTestResults || 0).toLocaleString(userLang)}
              <br />+{(tests || 0).toLocaleString(userLang)}
            </div>
          )}
        {props.country === "United States" &&
          (vaccinesDistributed > 0 || newVaccinationsDistributed > 0) && (
            <div style={getStyle("VaccinationsD")}>
              <div
                style={{
                  paddingTop: "4px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                }}
              >
                Vaccinations Distributed
              </div>
              {(vaccinesDistributed || 0).toLocaleString(userLang)} (
              {((vaccinesDistributed / population) * 100).toLocaleString(
                userLang
              )}
              % of population)
              <br />+{(newVaccinationsDistributed || 0).toLocaleString(userLang)}
            </div>
          )}
        {props.country === "United States" &&
          (newVaccinationsInitiated > 0 || vaccinationsInitiated > 0) && (
            <div style={getStyle("VaccinationsI")}>
              <div
                style={{
                  paddingTop: "4px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                }}
              >
                Vaccinations Initiated
              </div>
              {(vaccinationsInitiated || 0).toLocaleString(userLang)} (
              {((vaccinationsInitiated / population) * 100).toLocaleString(
                userLang
              )}
              % of population)
              <br />+{(newVaccinationsInitiated || 0).toLocaleString(userLang)}
            </div>
          )}
        {props.country === "United States" &&
          (newVaccinationsCompleted > 0 || vaccinationsCompleted > 0) && (
            <div style={getStyle("VaccinationsC")}>
              <div
                style={{
                  paddingTop: "4px",
                  paddingBottom: "8px",
                  fontWeight: "700",
                }}
              >
                Vaccinations Completed
              </div>
              {(vaccinationsCompleted || 0).toLocaleString(userLang)} (
              {((vaccinationsCompleted / population) * 100).toLocaleString(
                userLang
              )}
              % of population)
              <br />+{(newVaccinationsCompleted || 0).toLocaleString(userLang)}
            </div>
          )}
      </div>
      <br />
      <br />
    </>
  );
};

export default NavBar;
