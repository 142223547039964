// src/components/NavBar.js

import React from "react";

const NavBar = ({ active, onClick, country, county }) => {
  let getStyle = (name) => {
    return active === name
      ? {
          border: "1px solid #fff",
          fontWeight: "500",
          color: "white",
          width: "150px",
          height: "30px",
          marginLeft: "2%",
          marginRight: "2%",
          textDecoration: "none",
          cursor: "pointer",
          paddingTop: "8px",
          paddingRight: "5%",
          paddingLeft: "5%",
          borderRadius: "5px",
        }
      : {
          width: "150px",
          height: "30px",
          marginLeft: "2%",
          marginRight: "2%",
          color: "#b1b8c3",
          textDecoration: "none",
          cursor: "pointer",
          border: "1px solid #fff",
          paddingTop: "8px",
          paddingRight: "5%",
          paddingLeft: "5%",
          borderRadius: "5px",
        };
  };
  return (
    <div
      style={{
        marginLeft: "0px",
        marginRight: "0px",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <span style={getStyle("Graphs")} onClick={onClick}>
        Graphs
      </span>
      <span style={getStyle("Tables")} onClick={onClick}>
        Tables
      </span>

      <span style={getStyle("Maps")} onClick={onClick}>
        Maps
      </span>
    </div>
  );
};

export default NavBar;
