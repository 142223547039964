let statePop = {
  All: 330766301,
  "All (Exclude NY and NJ)": 319762353,
  Alabama: 4887871,
  Alaska: 737438,
  Arizona: 7171646,
  Arkansas: 3013825,
  California: 39557045,
  Colorado: 5695564,
  Connecticut: 3572665,
  Delaware: 967171,
  "District Of Columbia": 702455,
  Florida: 21299325,
  Georgia: 10519475,
  Guam: 165768,
  Hawaii: 1420491,
  Idaho: 1754208,
  Illinois: 12741080,
  Indiana: 6691878,
  Iowa: 3156145,
  Kansas: 2911510,
  Kentucky: 4468402,
  Louisiana: 4659978,
  Maine: 1338404,
  Maryland: 6042718,
  Massachusetts: 6902197,
  Michigan: 9995915,
  Minnesota: 5611179,
  Mississippi: 2986530,
  Missouri: 6126452,
  Montana: 1062305,
  Nebraska: 1929268,
  Nevada: 3034392,
  "New Hampshire": 1356410,
  "New Jersey": 8908520,
  "New Mexico": 2095428,
  "New York": 19542209,
  "North Carolina": 10383620,
  "North Dakota": 760077,
  Ohio: 11689442,
  Oklahoma: 3943079,
  Oregon: 4190713,
  Pennsylvania: 12807060,
  "Puerto Rico": 3195153,
  "Rhode Island": 1057315,
  "South Carolina": 5084127,
  "South Dakota": 882235,
  Tennessee: 6770010,
  Texas: 28701845,
  Utah: 3161105,
  Vermont: 626299,
  Virginia: 8517685,
  Washington: 7535591,
  "West Virginia": 1805832,
  Wisconsin: 5813568,
  Wyoming: 577737,
};

export default statePop;
