// src/components/NavBar.js

import React from "react";

const NavBar = ({ active, onClick, country, county }) => {
  let getStyle = (name) => {
    return active === name
      ? {
          border: "1px solid #fff",
          fontWeight: "500",
          fontSize: "14px",
          color: "white",
          width: "125px",
          height: "25px",
          marginLeft: "2%",
          marginRight: "2%",
          textDecoration: "none",
          cursor: "pointer",
          paddingTop: "8px",
          paddingRight: "5%",
          paddingLeft: "5%",
          borderRadius: "5px",
        }
      : {
          width: "125px",
          height: "25px",

          fontSize: "14px",
          marginLeft: "2%",
          marginRight: "2%",
          color: "#b1b8c3",
          textDecoration: "none",
          cursor: "pointer",
          border: "1px solid #fff",
          paddingTop: "8px",
          paddingRight: "5%",
          paddingLeft: "5%",
          borderRadius: "5px",
        };
  };
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "750px",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <div
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(130px, .75fr))",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <span style={getStyle("New Cases")} onClick={onClick}>
          New Cases
        </span>
        <span style={getStyle("Cumulative Cases")} onClick={onClick}>
          Cumulative Cases
        </span>
        <span style={getStyle("New Deaths")} onClick={onClick}>
          New Deaths
        </span>
        <span style={getStyle("Cumulative Deaths")} onClick={onClick}>
          Cumulative Deaths
        </span>
        <span style={getStyle("R")} onClick={onClick}>
          R
        </span>
      </div>
    </div>
  );
};

export default NavBar;
