// src/components/NavBar.js
import React, { useState, useEffect } from "react";
import queryString from "query-string";
import "./rvStyles.css";
import { Column, Table } from "react-virtualized";
import MapBarView from "./mapViewBar";
import { CSVLink } from "react-csv";
import Select from "react-select";

const TableView = (props) => {
  const [sortKey, setSortKey] = useState("name");
  const [sortDirection, setSortDirection] = useState("ASC");
  const [view, setView] = useState("Global");

  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.globalFips) {
      setData(formatData(props.globalFips));
    }
  }, [props.globalFips, props.fips, props.stateFips]);

  let formatData = (d) => {
    return Object.values(d)
      .map((item) => ({
        //fips:item.fips,
        name: item.name,
        positive: +item.positive,
        newCases: +item.newCases,
        newCasePercentage: +item.newCasePercentage.toLocaleString(),
        county: item.county,
        state: item.state,
        country: item.country,
        positivePercentage: +item.positivePercentage.toLocaleString(),
        death: +item.death,
        deathPercentage: +item.deathPercentage.toLocaleString(),
        newDeaths: +item.newDeaths,
        newDeathsPercentage: +item.newDeathsPercentage.toLocaleString(),
        cfr: (100 * (item.death / item.positive)).toLocaleString(),
        R: isNaN(item.R) ? 0 : item.R.toLocaleString(),
      }))
      .filter((v) => v.name !== "Diamond Princess");
  };

  let filterData = (filterStates) => {
    let tD = formatData(props.fips).filter(
      (v) => filterStates.includes(v.state) || !filterStates.length
    );
    setData(
      tD.sort((a, b) => {
        let aV = isNaN(a[sortKey]) ? 0 : a[sortKey];
        let bV = isNaN(b[sortKey]) ? 0 : b[sortKey];
        return sortDirection === "ASC" ? bV - aV : aV - bV;
      })
    );
    setData(tD);
  };

  let setSortedField = (key) => {
    let dir = sortDirection;
    if (key.sortBy !== sortKey) {
      dir = "ASC";
    } else {
      dir = dir === "ASC" ? "DESC" : "ASC";
    }
    setData(
      data.sort((a, b) => {
        let aV = isNaN(a[key.sortBy]) ? 0 : a[key.sortBy];
        let bV = isNaN(b[key.sortBy]) ? 0 : b[key.sortBy];
        return dir === "ASC" ? bV - aV : aV - bV;
      })
    );
    setSortKey(key.sortBy);
    setSortDirection(dir);
  };

  // let str= ''
  // console.log(data.forEach(v=>{
  //   Object.values(v).forEach(val=>{
  //     str=str+'|'+val;

  //   })
  //   str=str+'\n';
  // }))
  // console.log(str);
  return (
    <>
      {/* <table style={{border:'1px solid white', margin:'10px'}}>
      <thead>
        <tr style={{border:'1px solid white', margin:'10px',  height: '50px',
  verticalAlign: 'bottom'}}>
          <th onClick={() => setSortedField('name')} >Name</th>
          <th onClick={() => setSortedField('positive')}>Cases</th>
          <th onClick={() => setSortedField('positivePercentage')} >% Population confirmed</th>
          <th onClick={() => setSortedField('death')} >Deaths</th>
          <th onClick={() => setSortedField('deathPercentage')} >% Population Deaths</th>
          <th onClick={() => setSortedField('cfr')} >CFR</th>
          <th onClick={() => setSortedField('R')} >R</th>
        </tr>
      </thead>
      <tbody>
        {data &&data.map(((v)=>renderRow(v)))}
        </tbody>
    </table> */}
      <MapBarView
        active={view}
        onClick={(e) => {
          setView(e.target.innerHTML);
          if (e.target.innerHTML === "Global") {
            setData(formatData(props.globalFips));
          }
          if (e.target.innerHTML === "United States Counties") {
            setData(formatData(props.fips));
          }
          if (e.target.innerHTML === "United States States") {
            setData(formatData(props.stateFips));
          }
        }}
      />

      {view === "United States Counties" && (
        //   <Select
        //   defaultValue={[]}
        //   isMulti
        //   name="colors"
        //   options={[...new Set(Object.values(props.fips).map(v=>v.state))]}
        //   className="basic-multi-select"
        //   classNamePrefix="select"
        // />
        <div
          style={{
            marginTop: "10px",
            marginLeft: "0px",
            marginRight: "0px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(400px, 4fr))",
            alignItems: "right",
            justifyItems: "right",
            overflow: "scroll",
            width: "100vw",
            marginBottom: "10px",
          }}
        >
          <Select
            placeholder="Filter by state"
            isSearchable
            isMulti
            className="basic-multi-select"
            defaultValue={"United States"}
            // value={{
            //   label: this.state.country,
            //   value: this.state.country,
            // }}
            onChange={async (e) => {
              if (e) {
                filterData(e.map((e) => e.value));
              } else {
                filterData(
                  [
                    ...new Set(
                      Object.values(props.fips || {}).map((s) => s.state)
                    ),
                  ].sort()
                );
              }
            }}
            options={[
              ...new Set(Object.values(props.fips || {}).map((s) => s.state)),
            ]
              .sort()
              .map((s) => ({ value: s, label: s }))}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: "50px",
                maxHeight: "75px",
                minWidth: "300px",
                maxWidth: "500px",
                overflow: "scroll",
                marginRight: "100px",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
        </div>
      )}
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1600px",
          alignItems: "center",
          justifyItems: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            marginLeft: "0px",
            marginRight: "0px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
            alignItems: "left",
            justifyItems: "left",
            overflow: "scroll",
            marginBottom: "10px",
          }}
        >
          <Table
            sortDirection
            width={1600}
            height={600}
            headerHeight={20}
            rowHeight={70}
            rowCount={data.length}
            rowGetter={({ index }) => data[index]}
            sort={setSortedField}
            headerStyle={{
              whiteSpace: "normal",
              overflow: "wrap",
            }}
            style={{
              whiteSpace: "normal",
            }}
          >
            <Column
              style={{
                whiteSpace: "normal",
              }}
              label="Name"
              dataKey="name"
              height={70}
              width={200}
              cellRenderer={(v) => {
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={async () => {
                      let url =
                        "/?" +
                        queryString.stringify({
                          county: v.rowData.county,
                          state: v.rowData.state,
                          country: v.rowData.country,
                        });
                      await window.history.replaceState(
                        undefined,
                        undefined,
                        url
                      );
                      if (view === "Global") {
                        props.onCountryChange(v.rowData.country);
                      }
                      if (view === "United States Counties") {
                        props.onCountyChange(v.rowData.county);
                      }
                      if (view === "United States States") {
                        props.onStateChange(v.rowData.state);
                      }
                      props.updateMainView({ target: { innerHTML: "Graphs" } });
                    }}
                  >
                    {v.rowData[v.dataKey]}
                  </div>
                );
              }}
            />
            <Column width={150} label="Cases" dataKey="positive" />
            <Column
              style={{
                whiteSpace: "normal",
              }}
              width={250}
              label="Cases % Pop"
              dataKey="positivePercentage"
              cellRenderer={(v) => {
                return <div>{v.rowData[v.dataKey]}%</div>;
              }}
            />
            <Column width={150} label="Deaths" dataKey="death" />
            <Column
              width={250}
              label="Deaths % Pop"
              dataKey="deathPercentage"
              cellRenderer={(v) => {
                return <div>{v.rowData[v.dataKey]}%</div>;
              }}
            />
            <Column width={100} label="CFR" dataKey="cfr" />
            <Column
              width={100}
              label="R"
              dataKey="R"
              cellRenderer={(v) => {
                return <div title="14 day average">{v.rowData[v.dataKey]}</div>;
              }}
            />
            <Column width={200} label="New Deaths" dataKey="newDeaths" />
            <Column
              width={250}
              label="New Deaths % Pop"
              dataKey="newDeathsPercentage"
            />
            <Column width={200} label="New Cases" dataKey="newCases" />
            <Column
              width={205}
              label="New Cases % Pop"
              dataKey="newCasePercentage"
            />
          </Table>
        </div>
      </div>
      <CSVLink
        style={{ color: "#b1b8c3", float: "right", visibility: "hidden" }}
        data={data}
        filename={"covidprojection.csv"}
      >
        Download data as CSV
      </CSVLink>
    </>
  );
};

export default TableView;
