// src/components/NavBar.js

import React from "react";
const GraphViewBar = ({ active, onClick, country, county }) => {
  let getStyle = (name) => {
    return active === name
      ? {
        border: "1px solid #fff",
        fontWeight: "500",
        fontSize: "14px",
        color: "white",
        width: "160px",
        height: "25px",
        textDecoration: "none",
        cursor: "pointer",
        paddingTop: "8px",
        borderRadius: "5px",
      }
      : {
        width: "160px",
        height: "25px",
        fontSize: "14px",
        color: "#b1b8c3",
        textDecoration: "none",
        cursor: "pointer",
        border: "1px solid #fff",
        paddingTop: "8px",
        borderRadius: "5px",
      };
  };
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1200px",
        alignItems: "center",
        justifyItems: "center",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <span style={getStyle("Cases")} onClick={onClick}>
          Cases
        </span>
        <span style={getStyle("Deaths")} onClick={onClick}>
          Deaths
        </span>
        <span style={getStyle("Estimated Infections")} onClick={onClick}>
          Estimated Infections
        </span>
        {country === "United States" && county === "All" && (
          <span
            style={getStyle("Hospital Usage")}
            onClick={onClick}
          //to="/"
          >
            Hospital Usage
          </span>
        )}
        {country === "United States" && county === "All" && (
          <span style={getStyle("Testing")} onClick={onClick}>
            Testing
          </span>
        )}
        {country === "United States" && county === "All" && (
          <span style={getStyle("Vaccinations")} onClick={onClick}>
            Vaccinations
          </span>
        )}
      </div>
    </div>
  );
};

export default GraphViewBar;
