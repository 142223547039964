// src/components/NavBar.js

import React from "react";

const NavBar = ({ active, onClick, country, county, instance }) => {
  let getStyle = (name) => {
    return active === name
      ? {
          border: "1px solid #fff",
          fontWeight: "500",
          fontSize: "14px",
          color: "white",
          width: "160px",
          height: "25px",
          textDecoration: "none",
          cursor: "pointer",
          paddingTop: "8px",
          borderRadius: "5px",
        }
      : {
          width: "160px",
          height: "25px",
          fontSize: "14px",
          color: "#b1b8c3",
          textDecoration: "none",
          cursor: "pointer",
          border: "1px solid #fff",
          paddingTop: "8px",
          borderRadius: "5px",
        };
  };
  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "600px",
        alignItems: "center",
        justifyItems: "center",
        marginTop: "30px",
      }}
    >
      <div
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <span style={getStyle("Global")} onClick={onClick}>
          Global
        </span>
        <span style={getStyle("United States States")} onClick={onClick}>
          United States States
        </span>
        <span style={getStyle("United States Counties")} onClick={onClick}>
          United States Counties
        </span>
      </div>
    </div>
  );
};

export default NavBar;
