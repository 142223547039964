import React, { useState, memo } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import { scaleQuantize } from "d3-scale";
import ReactTooltip from "react-tooltip";
import MapBar from "./mapBar.js";
import MapBarView from "./mapViewBar.js";

const countyGeoUrl =
  "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";
const statesGeoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";
const globalGeoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
const MapPageSettings = {
  "New Deaths": {
    max: 0.0005,
    min: 0,
    dis: "Today's deaths as percentage of population",
    maxDis: ".0005%",
    minDis: "0.00%",
    scaleKey: "newDeathsPercentage",
  },
  "New Cases": {
    max: 0.05,
    min: 0,
    dis: "Today's cases as percentage of population",
    maxDis: "0.05%",
    minDis: "0.00%",
    scaleKey: "newCasePercentage",
  },
  "Cumulative Cases": {
    max: 3,
    min: 0,
    dis: "Cumulative cases as percentage of population",
    maxDis: "3.00%",
    minDis: "0.00%",
    scaleKey: "positivePercentage",
  },
  "Cumulative Deaths": {
    max: 0.1,
    min: 0,
    dis: "Cumulative deaths as percentage of population",
    maxDis: "0.10%",
    minDis: "0.00%",
    scaleKey: "deathPercentage",
  },
  R: {
    max: 1.5,
    min: 1,
    dis: "Average R value over last 14 days (does not account for testing)",
    maxDis: ">=1.5",
    minDis: "<1",
    scaleKey: "R",
  },
};

const MapChart = ({
  fips = {},
  globalFips = {},
  stateFips = {},
  setTooltipContent,
  max = 3,
}) => {
  // const [data, setData] = useState([]);
  const [mapPage, setMapPage] = useState("New Cases");
  const [mapView, setMapView] = useState("Global");
  // useEffect(() => {
  //   setData(globalFips);
  // }, [fipsLength]);

  const colorScale = scaleQuantize()
    .domain([MapPageSettings[mapPage].min, MapPageSettings[mapPage].max])
    .range([
      "#ffedea",
      "#ffcec5",
      "#ffad9f",
      "#ff8a75",
      "#ff5533",
      "#e2492d",
      "#be3d26",
      "#9a311f",
      "#782618",
    ]);
  return (
    <>
      <MapBarView
        active={mapView}
        onClick={(e) => {
          setMapView(e.target.innerHTML);
        }}
      />
      {mapView === "Global" && (
        <ComposableMap
          style={{ width: "50%", height: "auto", marginBottom: "10px" }}
        >
          <ZoomableGroup zoom={1}>
            <Geographies geography={globalGeoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  let data = globalFips;
                  const cur = data[geo.properties.ISO_A3];
                  let scaleVal = 0;
                  if (cur) {
                    scaleVal = cur[MapPageSettings[mapPage].scaleKey];
                  }
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      data-tip={
                        cur
                          ? `${cur.name} <br /> 
                    Cases: ${cur.positive} (${cur.positivePercentage.toFixed(
                              2
                            )}% of population)<br />
                    New Cases: ${cur.newCases} (${cur.newCasePercentage.toFixed(
                              3
                            )}% of population)<br />
                    Deaths: ${cur.death}(${cur.deathPercentage.toFixed(
                              2
                            )}% of population)<br />
                    New Deaths: ${
                      cur.newDeaths
                    } (${cur.newDeathsPercentage.toFixed(
                              4
                            )}% of population)<br />
                    R: ${
                      !isNaN(cur.R)
                        ? cur.R.toFixed(2)
                        : "Unable to Calculate R Value"
                    } `
                          : geo.properties.name
                      }
                      fill={cur ? colorScale(scaleVal) : "#000000"}
                      onMouseEnter={() => {
                        ReactTooltip.rebuild();
                      }}
                      onMouseLeave={() => {
                        ReactTooltip.rebuild();
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      )}
      {mapView === "United States Counties" && (
        <ComposableMap
          style={{ width: "50%", height: "auto", marginBottom: "10px" }}
          projection="geoAlbersUsa"
        >
          <ZoomableGroup zoom={1}>
            <Geographies geography={countyGeoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  let data = fips;
                  const cur = data[geo.id];
                  let scaleVal = 0;
                  if (cur) {
                    scaleVal = cur[MapPageSettings[mapPage].scaleKey];
                  }
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      data-tip={
                        cur
                          ? `${cur.name} <br /> 
                     Cases: ${cur.positive} (${cur.positivePercentage.toFixed(
                              2
                            )}% of population)<br />
                     New Cases: ${
                       cur.newCases
                     } (${cur.newCasePercentage.toFixed(
                              2
                            )}% of population)<br />
                     Deaths: ${cur.death}(${cur.deathPercentage.toFixed(
                              2
                            )}% of population)<br />
                     New Deaths: ${
                       cur.newDeaths
                     } (${cur.newDeathsPercentage.toFixed(
                              2
                            )}% of population)<br />
                     R: ${
                       !isNaN(cur.R)
                         ? cur.R.toFixed(2)
                         : "Unable to Calculate R Value"
                     } `
                          : geo.properties.name
                      }
                      fill={cur ? colorScale(scaleVal) : "#000000"}
                      onMouseEnter={() => {
                        ReactTooltip.rebuild();
                      }}
                      onMouseLeave={() => {
                        ReactTooltip.rebuild();
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      )}
      {mapView === "United States States" && (
        <ComposableMap
          style={{ width: "50%", height: "auto", marginBottom: "10px" }}
          projection="geoAlbersUsa"
        >
          <ZoomableGroup zoom={1}>
            <Geographies geography={statesGeoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  let data = stateFips;
                  const cur = data[geo.id];
                  let scaleVal = 0;
                  if (cur) {
                    scaleVal = cur[MapPageSettings[mapPage].scaleKey];
                  }
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      data-tip={
                        cur
                          ? `${cur.name} <br /> 
                     Cases: ${cur.positive} (${cur.positivePercentage.toFixed(
                              2
                            )}% of population)<br />
                     New Cases: ${
                       cur.newCases
                     } (${cur.newCasePercentage.toFixed(
                              2
                            )}% of population)<br />
                     Deaths: ${cur.death}(${cur.deathPercentage.toFixed(
                              2
                            )}% of population)<br />
                     New Deaths: ${
                       cur.newDeaths
                     } (${cur.newDeathsPercentage.toFixed(
                              2
                            )}% of population)<br />
                     R: ${
                       !isNaN(cur.R)
                         ? cur.R.toFixed(2)
                         : "Unable to Calculate R Value"
                     } `
                          : geo.properties.name
                      }
                      fill={cur ? colorScale(scaleVal) : "#000000"}
                      onMouseEnter={() => {
                        ReactTooltip.rebuild();
                      }}
                      onMouseLeave={() => {
                        ReactTooltip.rebuild();
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      )}
      <div style={{ textAlign: "center" }}>
        {MapPageSettings[mapPage].dis}
        <div
          style={{
            height: "30px",
            width: "70%",
            display: "flex",
            flexDirection: "row",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "15px",
            marginBottom: "15px",
          }}
        >
          <div>{MapPageSettings[mapPage].minDis}</div>
          <div
            style={{
              height: "30px",
              width: "80%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              background: "linear-gradient(to right, #ffedea,#782618)",
            }}
          />
          <div>{MapPageSettings[mapPage].maxDis}</div>
        </div>
      </div>

      <ReactTooltip multiline={true} />
      <MapBar
        active={mapPage}
        onClick={(e) => setMapPage(e.target.innerHTML)}
      />
    </>
  );
};

export default memo(MapChart);
