let statePop = {
  // "United States": {},
  Alabama: {
    schoolClosure: null,
    stateClosure: "04/04/2020",
    schoolReopening: null,
    stateReopening: "04/30/2020",
    closureLabel: "Safer at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/15/2020",
    maskOrderLabel: "Close Contact Mask Mandate",
  },
  Alaska: {
    schoolClosure: null,
    stateClosure: "03/28/2020",
    schoolReopening: null,
    stateReopening: "04/21/2020",
    closureLabel: "Hunker Down Order",
    openingLabel: "Phased Reopening",
  },
  Arizona: {
    schoolClosure: null,
    stateClosure: "03/31/2020",
    schoolReopening: null,
    stateReopening: "05/16/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Arkansas: {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
    stateMaskOrder: "07/20/2020",
    maskOrderLabel: "Universal Mask Mandate",
  },
  California: {
    schoolClosure: null,
    stateClosure: "03/19/2020",
    schoolReopening: null,
    stateReopening: "05/08/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "06/18/2020",
    maskOrderLabel: "Universal Mask Mandate",
  },
  Colorado: {
    schoolClosure: null,
    stateClosure: "03/26/2020",
    schoolReopening: null,
    stateReopening: "04/26/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/16/2020",
    maskOrderLabel: "Indoor Mask Mandate",
  },
  Connecticut: {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/20/2020",
    closureLabel: "Stay Safe, Stay Home",
    openingLabel: "Phased Reopening",
  },
  Delaware: {
    schoolClosure: null,
    stateClosure: "03/24/2020",
    schoolReopening: null,
    stateReopening: "05/15/2020",
    closureLabel: "Stay Safe, Stay Home",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "05/01/2020",
    maskOrderLabel: "Mask Mandate",
  },
  "District Of Columbia": {
    schoolClosure: null,
    stateClosure: "04/01/2020",
    schoolReopening: null,
    stateReopening: "06/01/2020",
    closureLabel: "Stay Safe, Stay Home",
    openingLabel: "Reopening",
    stateMaskOrder: "07/22/2020",
    maskOrderLabel: "Universal Mask Mandate",
  },
  Florida: {
    schoolClosure: null,
    stateClosure: "04/03/2020",
    schoolReopening: null,
    stateReopening: "05/01/2020",
    closureLabel: "Stay Safe, Stay Home",
    openingLabel: "Phased Reopening",
  },
  Georgia: {
    schoolClosure: null,
    stateClosure: "04/03/2020",
    schoolReopening: null,
    stateReopening: "04/30/2020",
    closureLabel: "Shelter in place",
    openingLabel: "General Reopening",
  },
  Guam: {
    schoolClosure: null,
    stateClosure: "03/14/2020",
    schoolReopening: null,
    stateReopening: "05/09/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Hawaii: {
    schoolClosure: null,
    stateClosure: "03/25/2020",
    schoolReopening: null,
    stateReopening: "05/07/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Idaho: {
    schoolClosure: null,
    stateClosure: "03/25/2020",
    schoolReopening: null,
    stateReopening: "04/30/2020",
    closureLabel: "Order to Self-Isolate",
    openingLabel: "Phased Reopening",
  },
  Illinois: {
    schoolClosure: null,
    stateClosure: "03/21/2020",
    schoolReopening: null,
    stateReopening: "05/30/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "06/01/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Indiana: {
    schoolClosure: null,
    stateClosure: "03/24/2020",
    schoolReopening: null,
    stateReopening: "05/04/2020",
    closureLabel: "Directive for Hoosiers to Stay Home",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/27/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Iowa: {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  Kansas: {
    schoolClosure: null,
    stateClosure: "03/30/2020",
    schoolReopening: null,
    stateReopening: "05/04/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Kentucky: {
    schoolClosure: null,
    stateClosure: "03/26/2020",
    schoolReopening: null,
    stateReopening: null,
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/10/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Louisiana: {
    schoolClosure: null,
    stateClosure: "03/26/2020",
    schoolReopening: null,
    stateReopening: "05/11/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Maine: {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/11/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/08/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Maryland: {
    schoolClosure: null,
    stateClosure: "03/30/2020",
    schoolReopening: null,
    stateReopening: "05/15/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "04/18/2020",
    maskOrderLabel: "Weak Mask Mandate",
  },
  Massachusetts: {
    schoolClosure: null,
    stateClosure: "03/24/2020",
    schoolReopening: null,
    stateReopening: "05/18/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "05/06/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Michigan: {
    schoolClosure: null,
    stateClosure: "03/24/2020",
    schoolReopening: null,
    stateReopening: "05/07/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/17/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Minnesota: {
    schoolClosure: null,
    stateClosure: "03/27/2020",
    schoolReopening: null,
    stateReopening: "04/27/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Mississippi: {
    schoolClosure: null,
    stateClosure: "04/03/2020",
    schoolReopening: null,
    stateReopening: "04/27/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/14/2020",
    maskOrderLabel: "Partial Mask Mandate",
  },
  Missouri: {
    schoolClosure: null,
    stateClosure: "03/28/2020",
    schoolReopening: null,
    stateReopening: "04/27/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Montana: {
    schoolClosure: null,
    stateClosure: "03/20/2020",
    schoolReopening: null,
    stateReopening: null,
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Nebraska: {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  Nevada: {
    schoolClosure: null,
    stateClosure: "04/01/2020",
    schoolReopening: null,
    stateReopening: "05/09/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "New Hampshire": {
    schoolClosure: null,
    stateClosure: "04/01/2020",
    schoolReopening: null,
    stateReopening: "05/04/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "New Jersey": {
    schoolClosure: null,
    stateClosure: "03/21/2020",
    schoolReopening: null,
    stateReopening: null,
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/08/2020",
    maskOrderLabel: "Mask Mandate",
  },
  "New Mexico": {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/16/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/09/2020",
    maskOrderLabel: "Strong Mask Mandate",
  },
  "New York": {
    schoolClosure: null,
    stateClosure: "03/20/2020",
    schoolReopening: null,
    stateReopening: "05/15/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "04/17/2020",
    maskOrderLabel: "Mask Mandate",
  },
  "North Carolina": {
    schoolClosure: null,
    stateClosure: "03/30/2020",
    schoolReopening: null,
    stateReopening: "05/08/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "North Dakota": {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  Ohio: {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/04/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/23/2020",
    maskOrderLabel: "Mask Mandate",
  },
  Oklahoma: {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  Oregon: {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/15/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Pennsylvania: {
    schoolClosure: null,
    stateClosure: "04/01/2020",
    schoolReopening: null,
    stateReopening: "05/08/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "Puerto Rico": {
    schoolClosure: null,
    stateClosure: "03/15/2020",
    schoolReopening: null,
    stateReopening: "05/04/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "Rhode Island": {
    schoolClosure: null,
    stateClosure: "03/20/2020",
    schoolReopening: null,
    stateReopening: null,
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "South Carolina": {
    schoolClosure: null,
    stateClosure: "03/28/2020",
    schoolReopening: null,
    stateReopening: "05/09/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  "South Dakota": {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  Tennessee: {
    schoolClosure: null,
    stateClosure: "03/31/2020",
    schoolReopening: null,
    stateReopening: "04/27/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Texas: {
    schoolClosure: null,
    stateClosure: "03/20/2020",
    schoolReopening: null,
    stateReopening: "05/01/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/02/2020",
    maskOrderLabel: "Partial Mask Mandate",
  },
  Utah: {
    schoolClosure: null,
    stateClosure: "04/02/2020",
    schoolReopening: null,
    stateReopening: "05/01/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Vermont: {
    schoolClosure: null,
    stateClosure: "03/20/2020",
    schoolReopening: null,
    stateReopening: null,
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Virginia: {
    schoolClosure: null,
    stateClosure: "03/25/2020",
    schoolReopening: null,
    stateReopening: "04/20/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
  },
  Washington: {
    schoolClosure: null,
    stateClosure: "03/23/2020",
    schoolReopening: null,
    stateReopening: "05/05/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "06/26/2020",
    maskOrderLabel: "Mask Mandate",
  },
  "West Virginia": {
    schoolClosure: null,
    stateClosure: "03/24/2020",
    schoolReopening: null,
    stateReopening: "04/30/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateMaskOrder: "07/07/2020",
    maskOrderLabel: "Weak Mask Mandate",
  },
  Wisconsin: {
    schoolClosure: null,
    stateClosure: "03/25/2020",
    schoolReopening: null,
    stateReopening: "04/29/2020",
    closureLabel: "Stay at Home Order",
    openingLabel: "Phased Reopening",
    stateReopened: "05/14/2020",
    reopenedLabel: "Court Ordered Reopening",
  },
  Wyoming: {
    schoolClosure: null,
    stateClosure: null,
    schoolReopening: null,
    stateReopening: null,
    closureLabel: null,
    openingLabel: null,
  },
  All: {
    firstState: "03/14/2020",
    fiftyPercent: "03/26/2020",
    max: "04/05/2020",
    reopening: "06/01/2020",
    firstStateLabel: "Guam Closed",
    fiftyPercentLabel: "50% Closed",
    maxLabel: "98% Closed (by population)",
    reopeningLabel: "100% Open/Reopening",
  },
  "All (Exclude NY and NJ)": {
    firstState: "03/14/2020",
    fiftyPercent: "03/26/2020",
    max: "04/05/2020",
    reopening: "06/01/2020",
    firstStateLabel: "Guam Closed",
    fiftyPercentLabel: "50% Closed",
    maxLabel: "98% Closed (by population)",
    reopeningLabel: "100% Open/Reopening",
  },
};

export default statePop;
