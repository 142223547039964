// src/components/NavBar.js

import React from "react";

const LineDot = ({ cx, cy, payload, color }) => {
  if (cx && cy) {
    return (
      <circle cx={cx} cy={cy} r={3} fill={color ? color : payload.color} />
    );
  }
  return null;
};

export default LineDot;
