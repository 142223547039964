import React, { Component } from "react";

import covidActNowLogo from './Black_Green_URL.png'
import "./App.css";
import superFips from "./fips.json";
import "../node_modules/react-vis/dist/style.css";
//import {VerticalGridLines, LineSeries, HorizontalGridLines,XAxis,YAxis,XYPlot} from 'react-vis';
import {
  ComposedChart,
  Area,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
  Legend,
} from "recharts";
import queryString from "query-string";
import LineDot from "./lineDot";
import stateABRMap from "./stateArb";
import Select from "react-select";
import Switch from "react-switch";
import statesList from "./stateCounty.js";
import axios from "axios";
import statePop from "./population.js";
import countryPopulations from "./countryPopulations.json";
import countyPopulations from "./countyPopulations.json";
import closures from "./closures";
import NavBar from "./navbar";
import DataBar from "./Databar";
import GraphViewBar from "./graphViewBar";
import TableView from "./tableview";
import Map from "./MapChart.js";
import ReactLoadingOverlay from "react-loading-overlay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

// Import your worker
import worker from "workerize-loader!./worker.js"; // eslint-disable-line import/no-webpack-loader-syntax

let instance1 = worker();
let instance2 = worker();
let instance3 = worker();

class App extends Component {
  constructor(props) {
    super(props);
    let qP = this.booleanize(queryString.parse(window.location.search));

    this.state = {
      data: [],
      calculatedData: [],
      stateData: [],
      states: Object.keys(statesList),
      state: "All",
      cSpread: 10,
      spread: 10,
      recloseSpread: 10,
      cumulativeSwitch: false,
      stateSwitch: false,
      estimatedIFR: 1,
      excludeIFR: false,
      DayPP: 0,
      RValueProjection: 1.3,
      RValueEstimated: 0.9,
      RPVProjection: 0.9,
      dayProjection: 0,
      indefiniteImmunity: true,
      immunityLength: 2,
      DayTO: 30,
      Rvalues: {},
      activePage: "Cases",
      activeMainPage: "Graphs",
      maxDate: new Date(),
      all: [],
      countries: {},
      counties: {},
      county: "All",
      project: false,
      country: "United States",
      startDate: new Date(2020, "00", 23),
      endDate: new Date(),
      min: new Date(2020, "00", 23),
      max: new Date(),
      dateRangeChanged: false,
      usData: [],
      upperIFR: 1.2,
      lowerIfR: 0.6,
      ...qP,
    };
  }

  async componentDidMount() {
    instance1.addEventListener("message", (message) => {
      if (message.data.setState) {
        this.setState({ ...message.data });
      }
    });
    instance2.addEventListener("message", (message) => {
      if (message.data.setState) {
        this.setState({ ...message.data });
      }
    });
    instance3.addEventListener("message", (message) => {
      if (message.data.setState) {
        this.setState({ ...message.data });
      }
    });
    //     console.log('here', states);
    //     let RReal= 0;
    //     let daySmear=[];
    //     await d3.csv(dataCSV).then((dataCSV) => {
    //       dataCSV.forEach((dateData, i)=>{
    //         let newCases = dateData.positive- +dataCSV[i ? i-1 :0 ].positive;
    //         if(dataCSV.length-31<i){
    //           let newCases = dateData.positive- +dataCSV[i ? i-1 :0 ].positive;
    //           let R = (newCases*10)/daySmear.reduce((a, b) => a + b, 0);
    //           daySmear.pop();
    //           daySmear.unshift(newCases);
    //           RReal = RReal + R;
    //         }else{
    //           if(daySmear.length===10){
    //             daySmear.pop();
    //           }
    //           daySmear.unshift(newCases);
    //         }
    //       });
    //       RReal = (RReal/(30)).toFixed(2);
    //        await this.setState({ usData:dataCSV, data:dataCSV, state:'United States', RValueEstimated: RReal, RPVProjection: RReal});
    //   }).catch(function(err) {
    //       throw err;
    //   })
    //   await d3.csv(stateDataCSV).then((dataCSV) => {
    //     let Rvalues ={};
    //     let states = ['United States', ...[...new Set(dataCSV.map(data=>data.state))].sort()];
    //     states.forEach(state=>{
    //       let RReal= 0;
    //       let daySmear=[];
    //       let d =[...dataCSV].filter(d=>d.state ===state);
    //       d.forEach((dateData, i)=>{
    //         if(dateData.state===state){
    //           let newCases = dateData.positive- +d[i ? i-1 :0 ].positive;
    //           if(d.length-31<i){
    //             let newCases = dateData.positive- +d[i ? i-1 :0 ].positive;
    //             let R = (newCases*10)/daySmear.reduce((a, b) => a + b, 0);
    //             daySmear.pop();
    //             daySmear.unshift(newCases);
    //             RReal = RReal + R;
    //           }else{
    //             if(daySmear.length===10){
    //               daySmear.pop();
    //             }
    //             daySmear.unshift(newCases);
    //           }
    //         }
    //       });
    //       RReal = (RReal/(30)).toFixed(2);

    //       Rvalues[state] = RReal;
    //     })
    //     Rvalues['United States'] = this.state.RValueEstimated;
    //      await this.setState({Rvalues, stateData:dataCSV, states});
    // }).catch(function(err) {
    //     throw err;
    // })

    // let data = await axios.get(
    //   "https://api.covidtracking.com/api/v1/us/daily.json"
    // );

    let urlP = queryString.parse(window.location.search);
    //await instance1.getCountries(new Date());
    !urlP["country"] || !urlP["state"] || urlP["state"] === "All"
      ? await this.onCountryChange(urlP["country"] || "United States")
      : await instance1.getCountries(new Date());
    await instance3.getStates();
    if (
      urlP["state"] &&
      urlP["state"] !== "All" &&
      !(urlP["county"] && urlP["county"] !== "All")
    )
      await this.onStateChange(urlP["state"]);
    await instance1.getCountries(new Date())
    await instance2.getCounties();
    if (urlP["county"] && urlP["county"] !== "All")
      await this.onCountyChangeV1(urlP["county"]);

    //let sD = await axios.get('https://docs.google.com/spreadsheets/d/e/2PACX-1vQSD9mm5HTXhxAiHabZA6BPUByWBlP5HZ2jfOPEeGZkMB0ZFsmFBL5orqjIq22mjFNZ7n-11ObCylGn/pub?output=csv&gid=256513998', { 'Content-Type': 'application/json'});
    //console.log(sD);
    // if(urlP['country']){
    //   if(urlP['state'] && urlP['state']!=='All'){
    //     if(urlP['county'] && urlP['county']!=='All' ){
    //       await this.onCountyChange(urlP['county']);
    //     }else{
    //       await this.onStateChange(urlP['state']);
    //     }
    //   }
    // }
    // let dates = {};
    // data = data.data.sort(
    //   (a, b) => new Date(a.dateChecked) - new Date(b.dateChecked)
    // );
    // data = data.map((d) => {
    //   let dateChecked = this.formatStringToDate(d.date);
    //   dateChecked = dateChecked.setHours(
    //     dateChecked.getHours() + dateChecked.getTimezoneOffset() / 60
    //   );
    //   return { ...d, dateChecked, date: dateChecked };
    // });
    // dates = {};
    // data = data.filter((d) => {
    //   if (dates[d.dateChecked]) {
    //     return false;
    //   }
    //   dates[d.dateChecked] = true;
    //   return new Date(d.dateChecked) > new Date(28800000);
    // });
    // let maxDate = data[data.length - 1].dateChecked;
    // let RReal = 0;
    // let daySmear = [];
    // let usData = this.state.countries["United States"];
    // data.forEach((dateData, i) => {
    //   if (usData && usData[i]) {
    //     dateData.positive = usData[i].positive;
    //     dateData.newCases =
    //       usData[i].positive - +usData[i ? i - 1 : 0].positive;
    //     dateData.positiveIncrease =
    //       usData[i].positive - +usData[i ? i - 1 : 0].positive;
    //     dateData.totaldeath = usData[i].death;
    //     dateData.death = usData[i].death;
    //     dateData.newdeath = usData[i].death - +usData[i ? i - 1 : 0].death;
    //     let newCases = usData[i] - +usData[i ? i - 1 : 0].positive;
    //     if (data.length - 31 < i) {
    //       let newCases = usData[i].positive - +usData[i ? i - 1 : 0].positive;
    //       let average = daySmear.reduce((a, b) => a + b, 0);
    //       if (average <= 0) {
    //         average = 1;
    //       }
    //       let R = (newCases * daySmear.length) / average;
    //       daySmear.pop();
    //       daySmear.unshift(newCases);
    //       RReal = RReal + R;
    //     } else {
    //       if (daySmear.length === 7) {
    //         daySmear.pop();
    //       }
    //       daySmear.unshift(newCases);
    //     }
    //   } else {
    //     data.pop();
    //   }
    // });

    // let urlP =queryString.parse(window.location.search);
    // if(urlP['country']){
    //   await this.onCountryChange(urlP['country']);
    // }else{
    //   RReal = (RReal / 30).toFixed(2);
    // let states = Object.keys(statesList);
    // await this.setState({
    //   usData: dataCSV,
    //   states,
    // });
    //   await this.calcProjections();
    //   await this.setState({
    //     RPValueEstimated: +this.state.tRReal.toFixed(2),
    //     RValueEstimated: +this.state.tRReal.toFixed(2),
    //     RPVProjection: +this.state.tRReal.toFixed(2),
    //     RReal: +this.state.tRReal.toFixed(2),
    //     population: statePop["All"],
    //   });

    // }
  }
  booleanize(v) {
    Object.keys(v).forEach((k) => {
      if (v[k] === "false" || v[k] === "true") {
        v[k] = v[k] === "true";
      }
    });
    return v;
  }

  async setStartDate(date) {
    await this.setState({ startDate: date, dateRangeChanged: true });
  }

  async setEndDate(date) {
    await this.setState({ endDate: date, dateRangeChanged: true });
  }

  async resetDates() {
    await this.setState({
      dateRangeChanged: false,
      startDate: new Date(2020, "00", 23),
      endDate: new Date(),
    });
  }

  async handleChange(e) {
    let value = e.target.value;
    if (e.target.max && +e.target.max < value) {
      value = e.target.max;
    }
    // if(!isNaN(e.target.min) && !isNaN(value) && value<e.target.min){
    //     value = e.target.min;
    // }

    await this.setState({ [e.target.name]: value });
    this.calcProjections();
  }

  async handleSwitch() {
    await this.setState({ cumulativeSwitch: !this.state.cumulativeSwitch });
    this.calcProjections();
  }

  async handleStateSwitch() {
    //  await this.setState({stateSwitch: !this.state.stateSwitch, data: !this.state.stateSwitch ? this.state.usData : this.state.stateData});
  }

  updateURLParams() {
    let params = {};
    params.country = this.state.country;
    params.state = this.state.state;
    params.county = this.state.county;
    params.activePage = this.state.activePage;
    params.activeMainPage = this.state.activeMainPage;
    if (this.state.project) {
      let {
        project,
        cumulativeSwitch,
        stateSwitch,
        estimatedIFR,
        excludeIFR,
        DayPP,
        RValueProjection,
        RValueEstimated,
        RPVProjection,
        indefiniteImmunity,
        immunityLength,
        dayProjection,
        DayTO,
      } = this.state;
      params = {
        ...params,
        project,
        cumulativeSwitch,
        stateSwitch,
        estimatedIFR,
        excludeIFR,
        DayPP,
        RValueProjection,
        RValueEstimated,
        RPVProjection,
        indefiniteImmunity,
        immunityLength,
        dayProjection,
        DayTO,
      };
    }

    window.history.replaceState(
      undefined,
      undefined,
      "?" + queryString.stringify(params)
    );
  }

  generateDates(d0, d1) {
    let dates = [];
    dates[0] = d0;
    let end = d1;
    let day = 60 * 60 * 24 * 1000;
    while (dates[dates.length - 1] < end) {
      let date = new Date();
      dates.push(
        new Date(date.setTime(dates[dates.length - 1].getTime() + day))
      );
    }
    return dates;
  }
  async onCountryChange(v) {
    let data;
    if (v === "United States") {
      this.onStateChange("All");
    } else {
      await instance1.getCountries(
        this.state.countriesLastUpdated || new Date()
      );
      data = this.state.countries[v];
      let population = countryPopulations[v].population;
      data = data.map((d) => {
        let dateChecked = new Date(d.date);
        dateChecked = dateChecked.setHours(
          dateChecked.getHours() + dateChecked.getTimezoneOffset() / 60
        );
        return { ...d, dateChecked, date: dateChecked };
      });
      let dates = {};
      data.filter((d) => {
        if (dates[d.dateChecked]) {
          return false;
        }
        dates[d.dateChecked] = true;
        return new Date(d.dateChecked) > new Date(28800000);
      });
      let maxDate = data[data.length - 1].date;
      let RReal = 0;
      let daySmear = [];
      data.forEach((dateData, i) => {
        let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
        if (data.length - 31 < i) {
          let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
          let average = daySmear.reduce((a, b) => a + b, 0);
          if (average <= 0) {
            average = 1;
          }
          let R = (newCases * daySmear.length) / average;
          daySmear.pop();
          daySmear.unshift(newCases);
          RReal = RReal + R;
        } else {
          if (daySmear.length === 7) {
            daySmear.pop();
          }
          daySmear.unshift(newCases);
        }
      });
      RReal = (RReal / 30).toFixed(2);
      await this.setState({
        data,
        country: v,
        state: "All",
        county: "All",
        RReal: +RReal,
        activePage:
          this.state.activePage === "Cases" ||
            this.state.activePage === "Estimated Infections" ||
            this.state.activePage === "Deaths"
            ? this.state.activePage
            : "Cases",
        RPValueEstimated: RReal,
        //RValueEstimated: RReal,
        //RPVProjection: RReal,
        maxDate,
        population,
      });
      await this.calcProjections();
      return await this.setState({
        RPValueEstimated: +this.state.tRReal.toFixed(2),
        //RValueEstimated: +this.state.tRReal.toFixed(2),
        //RPVProjection: +this.state.tRReal.toFixed(2),
        RReal: +this.state.tRReal.toFixed(2),
      });
    }
  }

  async onCountyChange(v) {
    let data;
    let qP = this.booleanize(queryString.parse(window.location.search));
    await this.setState({ state: qP.state });
    if (v === "All") {
      this.onStateChange(this.state.state);
    } else {
      data = this.state.counties[this.state.state][v];
      let population = countyPopulations[this.state.state][v];
      data = data.map((d) => {
        let dateChecked = new Date(d.date);
        dateChecked = dateChecked.setHours(
          dateChecked.getHours() + dateChecked.getTimezoneOffset() / 60
        );
        return { ...d, dateChecked, date: dateChecked };
      });
      let dates = {};
      data.filter((d) => {
        if (dates[d.dateChecked]) {
          return false;
        }
        dates[d.dateChecked] = true;
        return new Date(d.dateChecked) > new Date(28800000);
      });
      let maxDate = data[data.length - 1].date;
      let RReal = 0;
      let daySmear = [];
      data.forEach((dateData, i) => {
        let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
        if (data.length - 31 < i) {
          let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
          let average = daySmear.reduce((a, b) => a + b, 0);
          if (average <= 0) {
            average = 1;
          }
          let R = (newCases * daySmear.length) / average;
          daySmear.pop();
          daySmear.unshift(newCases);
          RReal = RReal + R;
        } else {
          if (daySmear.length === 7) {
            daySmear.pop();
          }
          daySmear.unshift(newCases);
        }
      });
      RReal = (RReal / 30).toFixed(2);
      await this.setState({
        data,
        county: v,
        RReal: +RReal,
        RPValueEstimated: RReal,
        //RValueEstimated: RReal,
        activePage: "Cases",
        //RPVProjection: RReal,
        maxDate,
        population,
      });
      await this.calcProjections();
      await this.setState({
        RPValueEstimated: +this.state.tRReal.toFixed(2),
        //RValueEstimated: +this.state.tRReal.toFixed(2),
        //RPVProjection: +this.state.tRReal.toFixed(2),
        RReal: +this.state.tRReal.toFixed(2),
      });
    }
  }
  subtractObjects(o1, o2) {
    Object.keys(o1).forEach((v) => {
      if (o2[v] && v !== "date") {
        o1[v] = o1[v] - o2[v];
      }
    });
  }

  async onStateChange(v) {
    let data;
    let state = stateABRMap[v];
    if (v === "All" || v === "All (Exclude NY and NJ)") {

      data = (await axios.get("https://api.covidactnow.org/v2/country/US.timeseries.json?apiKey=2eba8ad0d1e348aab44a11e9226616b7"))
        .data;
      data = data.actualsTimeseries;
      console.log(data);
      data.pop();
      //let test = (await axios.get("https://data.covidactnow.org/snapshot/1610/v2/state/WA.timeseries.json")).data;
      // let usData = this.state.countries["United States"];
      // if (!usData) {
      //   await instance2.getCountries(
      //     this.state.countriesLastUpdated || new Date()
      //   );
      //   usData = this.state.countries["United States"];
      // }
      data.forEach((v, i) => {
        v.positive = data[i].cases;
        v.positiveIncrease = v.newCases;
        v.totalDeath = v.deaths + data[i - 1] ? data[i - 1].totaldeath : 0;
        v.death = v.deaths
        v.newDeath = data[i].newDeaths
        v.deathIncrease = data[i].newDeaths;
        v.dateChecked = v.date;

        v.hospitalizedCurrently = v.hospitalBeds.currentUsageCovid;
        v.hospitalizedCumulative = v.hospitalBeds.currentUsageCovid + data[i - 1] ? data[i - 1].hospitalizedCumulative : 0;
        v.inIcuCurrently = v.icuBeds.currentUsageCovid;
        v.inIcuCumulative = v.icuBeds.currentUsageCovid + + data[i - 1] ? data[i - 1].inIcuCumulative : 0;
        v.newVaccinationsCompleted = v.vaccinationsCompleted - (data[i - 1] ? data[i - 1].vaccinationsCompleted : 0) || 0;
        v.newVaccinationsInitiated = v.vaccinationsInitiated - (data[i - 1] ? data[i - 1].vaccinationsInitiated : 0) || 0;
        v.newVaccinationsDistributed = v.vaccinesDistributed - (data[i - 1] ? data[i - 1].vaccinesDistributed : 0) || 0;
        let totalTestResults = data[i].totalTestResults;
        v.totalTestResults = totalTestResults;
        v.totalTestResultsIncrease = totalTestResults - ((data[i - 1] ? data[i - 1].totalTestResults : 0) || 0);
      });
      data = data.map((d) => {
        let dateChecked = this.formatStringToDate(d.date);
        dateChecked = dateChecked.setHours(
          dateChecked.getHours() + dateChecked.getTimezoneOffset() / 60
        );
        return { ...d, dateChecked, date: dateChecked };
      });
    } else {
      if (!this.state.states) {
        await instance2.states();
      }
      data = (await axios.get(
        `https://api.covidactnow.org/v2/state/${state}.timeseries.json?apiKey=2eba8ad0d1e348aab44a11e9226616b7`
      )).data.actualsTimeseries;

      data.pop();
      /*
cases: 1
  ​
contactTracers: null
  ​
date: "2020-01-21"
  ​
dateChecked: "2020-01-21"
  ​
deaths: 0
  ​
hospitalBeds: Object { capacity: null, currentUsageTotal: null, currentUsageCovid: null, … }
  ​
icuBeds: Object { capacity: null, currentUsageTotal: null, currentUsageCovid: null, … }
  ​
negativeTests: null
  ​
newCases: 1
  ​
positiveTests: null
  ​
x: "2020-01-21"
      */
      let cc = 0;
      data.forEach((v, i) => {
        if (v.cases !== undefined && v.cases !== null) {
          cc = v.cases;
        }
        v.positive = cc
        v.positiveIncrease = v.newCases;
        v.totaldeath = v.deaths + data[i - 1] ? data[i - 1].totaldeath : 0;
        v.death = v.deaths
        v.newdeath = v.deaths;
        v.deathIncrease = v.deaths;
        v.dateChecked = v.date;
        v.dateChecked = v.date;
        v.hospitalizedCurrently = v.hospitalBeds.currentUsageCovid;
        v.hospitalizedCumulative = v.hospitalBeds.currentUsageCovid + data[i - 1] ? data[i - 1].hospitalizedCumulative : 0;
        v.inIcuCurrently = v.icuBeds.currentUsageCovid;
        v.inIcuCumulative = v.icuBeds.currentUsageCovid + data[i - 1] ? data[i - 1].inIcuCumulative : 0;
        v.newVaccinationsCompleted = v.vaccinationsCompleted - (data[i - 1] ? data[i - 1].vaccinationsCompleted : 0) || 0;
        v.newVaccinationsInitiated = v.vaccinationsInitiated - (data[i - 1] ? data[i - 1].vaccinationsInitiated : 0) || 0;
        v.newVaccinationsDistributed = v.vaccinesDistributed - (data[i - 1] ? data[i - 1].vaccinesDistributed : 0) || 0;
        let totalTestResults = +v.positiveTests + +v.negativeTests;;
        v.totalTestResults = totalTestResults;
        v.totalTestResultsIncrease = totalTestResults - ((data[i - 1] ? data[i - 1].totalTestResults : 0) || 0);
        /*​​​
vaccinationsCompleted: 1066991
  ​
vaccinationsInitiated: 2296133
  ​
vaccinesDistributed: 4165325
*/
        v.x = v.date;
      });
    }

    let dates = {};
    data = data.filter((d) => {
      if (dates[d.dateChecked]) {
        return false;
      }
      dates[d.dateChecked] = true;
      return new Date(d.dateChecked) > new Date(28800000);
    });
    let maxDate = data[data.length - 1].dateChecked;
    let RReal = 0;
    let daySmear = [];
    data.forEach((dateData, i) => {
      //let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
      if (data.length - 31 < i) {
        //let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
        let average = daySmear.reduce((a, b) => a + b, 0);
        if (average <= 0) {
          average = 1;
        }
        let R = (dateData.newCases * daySmear.length) / average;
        daySmear.pop();
        daySmear.unshift(dateData.newCases);
        RReal = RReal + R;
      } else {
        if (daySmear.length === 7) {
          daySmear.pop();
        }
        daySmear.unshift(dateData.newCases);
      }
    });
    RReal = (RReal / 30).toFixed(2);
    await this.setState({
      data,
      calculatedData: data,
      state: v,
      country: "United States",
      county: "All",
      RReal: +RReal,
      RPValueEstimated: RReal,
      //RValueEstimated: RReal,
      //RPVProjection: RReal,
      maxDate,
      population: statePop[v],
    });
    await this.calcProjections();
    return await this.setState({
      RPValueEstimated: +this.state.tRReal.toFixed(2),
      //RValueEstimated: +this.state.tRReal.toFixed(2),
      RPVProjection: +this.state.tRReal.toFixed(2),
      RReal: +this.state.tRReal.toFixed(2),
    });
  }


  async onCountyChangeV1(v) {
    if (v === 'All') {
      this.onStateChange(this.state.state);
      return;
    }
    let data;
    let fipsCode = superFips.find(val => val.state === stateABRMap[this.state.state] && val.name.includes(v));
    if (!this.state.states) {
      await instance2.states();
    }
    data = (await axios.get(
      `https://api.covidactnow.org/v2/county/${fipsCode.fips}.timeseries.json?apiKey=2eba8ad0d1e348aab44a11e9226616b7`
    )).data.actualsTimeseries;

    /*
cases: 1
​
contactTracers: null
​
date: "2020-01-21"
​
dateChecked: "2020-01-21"
​
deaths: 0
​
hospitalBeds: Object { capacity: null, currentUsageTotal: null, currentUsageCovid: null, … }
​
icuBeds: Object { capacity: null, currentUsageTotal: null, currentUsageCovid: null, … }
​
negativeTests: null
​
newCases: 1
​
positiveTests: null
​
x: "2020-01-21"
    */
    data.forEach((v, i) => {
      v.positive = v.cases;
      v.positiveIncrease = v.newCases;
      v.totaldeath = v.deaths + data[i - 1] ? data[i - 1].totaldeath : 0;
      v.death = v.deaths
      v.newdeath = v.deaths;
      v.deathIncrease = v.deaths;
      v.dateChecked = v.date;
      v.dateChecked = v.date;
      v.hospitalizedCurrently = v.hospitalBeds.currentUsageCovid;
      v.hospitalizedCumulative = v.hospitalBeds.currentUsageCovid + data[i - 1] ? data[i - 1].hospitalizedCumulative : 0;
      v.inIcuCurrently = v.icuBeds.currentUsageCovid;
      v.inIcuCumulative = v.icuBeds.currentUsageCovid + data[i - 1] ? data[i - 1].inIcuCumulative : 0;
      v.newVaccinationsCompleted = v.vaccinationsCompleted - (data[i - 1] ? data[i - 1].vaccinationsCompleted : 0) || 0;
      v.newVaccinationsInitiated = v.vaccinationsInitiated - (data[i - 1] ? data[i - 1].vaccinationsInitiated : 0) || 0;
      v.newVaccinationsDistributed = v.vaccinesDistributed - (data[i - 1] ? data[i - 1].vaccinesDistributed : 0) || 0;
      let totalTestResults = +v.positiveTests + +v.negativeTests;;
      v.totalTestResults = totalTestResults;
      v.totalTestResultsIncrease = totalTestResults - ((data[i - 1] ? data[i - 1].totalTestResults : 0) || 0);
      /*​​​
vaccinationsCompleted: 1066991
​
vaccinationsInitiated: 2296133
​
vaccinesDistributed: 4165325
*/
      v.x = v.date;
    });

    let dates = {};
    data = data.filter((d) => {
      if (dates[d.dateChecked]) {
        return false;
      }
      dates[d.dateChecked] = true;
      return new Date(d.dateChecked) > new Date(28800000) && d.cases;
    });
    let maxDate = data[data.length - 1].dateChecked;
    let RReal = 0;
    let daySmear = [];
    data.forEach((dateData, i) => {
      //let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
      if (data.length - 31 < i) {
        //let newCases = dateData.positive - +data[i ? i - 1 : 0].positive;
        let average = daySmear.reduce((a, b) => a + b, 0);
        if (average <= 0) {
          average = 1;
        }
        let R = (dateData.newCases * daySmear.length) / average;
        daySmear.pop();
        daySmear.unshift(dateData.newCases);
        RReal = RReal + R;
      } else {
        if (daySmear.length === 7) {
          daySmear.pop();
        }
        daySmear.unshift(dateData.newCases);
      }
    });
    RReal = (RReal / 30).toFixed(2);
    await this.setState({
      data,
      calculatedData: data,
      county: v,
      RReal: +RReal,
      RPValueEstimated: RReal,
      //RValueEstimated: RReal,
      //RPVProjection: RReal,
      maxDate,
      population: countyPopulations[this.state.state][v],
    });
    await this.calcProjections();
    return await this.setState({
      RPValueEstimated: +this.state.tRReal.toFixed(2),
      //RValueEstimated: +this.state.tRReal.toFixed(2),
      RPVProjection: +this.state.tRReal.toFixed(2),
      RReal: +this.state.tRReal.toFixed(2),
    });
  }




  changePage(e) {
    if (e.target.innerHTML === "Estimated Infections") {
      this.setState({ activePage: e.target.innerHTML, project: false });
    } else {
      this.setState({ activePage: e.target.innerHTML });
    }
  }

  changeMainPage(e) {
    this.setState({ activeMainPage: e.target.innerHTML });
  }
  setContent(data) {
    this.setState({ content: data });
  }

  dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  getDateDifference(date1, date2) {
    let differenceInTime =
      new Date(date2.setHours(0, 0, 0, 0)).getTime() -
      new Date(date1.setHours(0, 0, 0, 0)).getTime();
    // To calculate the no. of days between two dates
    return differenceInTime / (1000 * 3600 * 24);
  }

  formatStringToDate(string) {
    let s = string.toString().split('-');
    let d = new Date(
      s[0],
      +s[1] - 1,
      s[2],
      0,
      0,
      0,
      0
    );
    return d;
  }

  getFormattedDate(date) {
    if (date) {
      let dDate = new Date(date);
      var year = dDate.getFullYear();

      var month = (1 + dDate.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = dDate.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }
  }
  convertData() {
    let dead = 0;
    let totalCases = 0;
    let cfr;
    let tCFR;
    let RReal = 0;
    let daySmear = [];
    let removed = 0;
    let hospitalizationRate = 0;
    let icuRate = 0;
    let ventilatorRate = 0;
    let immunityLength = Math.round(this.state.immunityLength * 365);
    let averageR = [];
    let averageT = [];
    let sevenDay = [];
    let sevenDayDeath = [];
    let sevenDayTesting = [];
    let aR = [];
    let data = this.state.data.map((dateData, i) => {
      dead = dead + +dateData.death;
      totalCases = totalCases + +dateData.positive;
      let newCases =
        dateData.positive - +this.state.data[i ? i - 1 : 0].positive;
      let aT = averageT.reduce((a, b) => a + b, 0) / averageT.length || 1;
      let R =
        ((newCases / aT || 0) * daySmear.length) /
        daySmear.reduce((a, b) => a + b, 0) || 0;
      if (this.state.data.length - 31 < i) {
        RReal = RReal + R;
        daySmear.pop();
        daySmear.unshift(newCases / aT);
      } else {
        if (daySmear.length === 7) {
          daySmear.pop();
        }
        daySmear.unshift(newCases / aT);
      }
      sevenDay.unshift(newCases);
      sevenDayTesting.unshift(dateData.totalTestResultsIncrease);
      let newdeath = dateData.death - +this.state.data[i ? i - 1 : 0].death;
      let death = this.state.cumulativeSwitch
        ? dateData.death
        : dateData.death - this.state.data[i ? i - 1 : 0].death;
      sevenDayDeath.unshift(death);
      if (averageR.length === 7) {
        averageR.pop();
        averageT.pop();
        sevenDay.pop();
        sevenDayTesting.pop();
        sevenDayDeath.pop();
      }
      averageT.unshift(
        !isNaN(dateData.totalTestResultsIncrease)
          ? dateData.totalTestResultsIncrease
          : 1
      );
      averageR.unshift(
        ((newCases / aT) * daySmear.length) /
        daySmear.reduce((a, b) => a + b, 0) || 0
      );
      if (i > immunityLength) {
        removed =
          removed -
          (+this.state.data[i - immunityLength].positive -
            +this.state.data[i - immunityLength - 1].positive);
      }
      removed = removed + newCases + dateData.newVaccinationsCompleted;

      let Rifr = averageR.reduce((a, b) => a + b, 0) / averageR.length;
      Rifr = Rifr < 10 && Rifr > 0 ? Rifr : 0;
      if (this.state.data.length - 31 < i) {
        if (Rifr > 0 && Rifr < 5) {
          aR.push(Rifr);
        }
      }
      let averageDeath =
        sevenDayDeath.reduce((a, b) => +a + +b, 0) / sevenDayDeath.length;

      return {
        color: "rgba(116, 185, 137, 0.64)",
        dateChecked: dateData.dateChecked,
        x: new Date(dateData.date),
        confirmed: this.state.cumulativeSwitch
          ? +dateData.positive
          : +dateData.positive - this.state.data[i ? i - 1 : 0].positive,
        death: this.state.cumulativeSwitch
          ? dateData.death
          : dateData.death - this.state.data[i ? i - 1 : 0].death,
        positive: +dateData.positive,
        newCases: dateData.positive - +this.state.data[i ? i - 1 : 0].positive,
        R: this.state.RValueEstimated,
        Rifr,
        totaldeath: +dateData.death,
        tests: dateData.totalTestResultsIncrease,
        totalTestResults: dateData.totalTestResults,
        newdeath,
        averageDeath,
        hospitalizedIncrease: dateData.hospitalizedIncrease,
        hospitalizedCurrently: dateData.hospitalizedCurrently,
        hospitalizedCumulative: dateData.hospitalizedCumulative,
        inIcuCumulative: dateData.inIcuCumulative,
        inIcuCurrently: dateData.inIcuCurrently,
        onVentilatorCurrently: dateData.onVentilatorCumulative,
        onVentilatorCumulative: dateData.onVentilatorCurrently,
        vaccinationsCompleted: dateData.vaccinationsCompleted,
        newVaccinationsCompleted: dateData.newVaccinationsCompleted,
        vaccinationsInitiated: dateData.vaccinationsInitiated,
        newVaccinationsInitiated: dateData.newVaccinationsInitiated,
        vaccinesDistributed: dateData.vaccinesDistributed,
        newVaccinationsDistributed: dateData.newVaccinationsDistributed,
        cTests: dateData.totalTestResults,
        nPos:
          dateData.positiveIncrease ||
          dateData.positive - this.state.data[i ? i - 1 : 0].positive,
        cPos: dateData.positive,
        testP: (
          ((dateData.positive - this.state.data[i ? i - 1 : 0].positive) /
            +dateData.totalTestResultsIncrease) *
          100
        ).toFixed(2),
        testPT: ((dateData.positive / dateData.totalTestResults) * 100).toFixed(
          2
        ),
        average: (+sevenDay.reduce((a, b) => a + b) / sevenDay.length).toFixed(
          0
        ),
        averageTest: +(
          sevenDayTesting.reduce((a, b) => a + b) / sevenDayTesting.length
        ).toFixed(0),
      };
    });
    data.forEach((v, i) => {
      if (i > 25) {
        let IFRDeathsLower = +(
          v.averageDeath *
          (100 / this.state.lowerIfR)
        ).toFixed(0);
        let IFRDeathsUpper = +(
          v.averageDeath *
          (100 / this.state.upperIFR)
        ).toFixed(0);
        let lowerIFRPop = (
          (IFRDeathsUpper / this.state.population) *
          100
        ).toFixed(2);
        let upperIFRPop = (
          (IFRDeathsLower / this.state.population) *
          100
        ).toFixed(2);
        data[i - 25].IFRDeaths = [IFRDeathsUpper, IFRDeathsLower];
        data[i - 25].lowerIFRPop = lowerIFRPop;
        data[i - 25].upperIFRPop = upperIFRPop;
      }
    });
    RReal = RReal / 30;
    aR = aR.reduce((a, b) => a + b, 0) / aR.length;
    if (data.length > 30) {
      cfr =
        (+data[data.length - 1].totaldeath -
          +data[data.length - 15].totaldeath) /
        (+data[data.length - 15].positive - +data[data.length - 30].positive);
      tCFR =
        +data[data.length - 1].totaldeath / +data[data.length - 1].positive;
      if (!cfr) {
        cfr = tCFR;
      }
      hospitalizationRate =
        +data[data.length - 1].hospitalizedCumulative /
        +data[data.length - 1].positive;
      icuRate =
        +data[data.length - 1].inIcuCumulative /
        +data[data.length - 1].positive;
      ventilatorRate =
        +data[data.length - 1].onVentilatorCumulative /
        +data[data.length - 1].positive;
    }
    return {
      data,
      dead,
      totalCases,
      cfr,
      tCFR,
      RReal: aR,
      daySmear,
      removed,
      immunityLength,
      hospitalizationRate,
      icuRate,
      ventilatorRate,
    };
  }

  estimatedPast(pastData, data, cfr, maxCases, removed) {
    let hospitalizedCurrently = [];
    let c = 0;
    while (c < 30 && pastData.length > 0) {
      let start = pastData.length - 13;
      let sum = 0;
      let newCases = 0;

      for (start; start < pastData.length - 3; start++) {
        let ppd = this.state.RPValueEstimated / this.state.cSpread;
        newCases = +pastData[start].newCases * ppd;
        sum = sum + newCases;
      }
      if (+data[data.length - 1].positive - removed > maxCases) {
        sum = maxCases - (+data[data.length - 1].positive - removed);
      }
      if (sum < 0) {
        sum = 0;
      }
      newCases = Math.round(sum);
      sum = Math.round(sum + +pastData[pastData.length - 1].positive);
      let nDate = new Date(pastData[pastData.length - 1].x);
      if (hospitalizedCurrently.length === 14) {
        hospitalizedCurrently.pop();
      }
      hospitalizedCurrently.unshift((data[data.length - c - 1] || {}).newCases);
      pastData = [
        ...pastData,
        {
          color: "#ff9900",
          x: new Date(nDate.setDate(nDate.getDate() + 1)),
          dateChecked: this.dateToYMD(
            new Date(nDate.setDate(nDate.getDate() + 1))
          ),
          positive: sum,
          pestimated: this.state.cumulativeSwitch ? sum : newCases,
          newCases,
          R: this.state.RPValueEstimated,
          ppdeath: Math.round(
            this.state.cumulativeSwitch
              ? newCases * cfr + +data[data.length - (30 - c) - 1].totaldeath
              : sum * cfr - +pastData[pastData.length - 1].positive * cfr
          ),
          pastTotaldeath:
            newCases * cfr + +data[data.length - (30 - c) - 1].totaldeath,
        },
      ];
      c++;
    }
    pastData = pastData.slice(pastData.length - 30, pastData.length);
    c = 0;
    for (
      let i = data.length >= 30 ? data.length - 30 : 0;
      i < data.length && data.length > 0 && pastData.length === 30;
      i++
    ) {
      data[i].pestimated = pastData[c].pestimated;
      data[i].ppdeath = pastData[c].ppdeath;
      data[i].R = pastData[c].R;
      c++;
    }
    return { data, hospitalizedCurrently };
  }

  projectData(
    data,
    daysToProject,
    removed,
    maxCases,
    immunityLength,
    cfr,
    rValue,
    color,
    hospitalizationRate,
    icuRate,
    ventilatorRate,
    hospitalizedCurrently
  ) {
    let c = 0;
    while (c < daysToProject && data.length > 0) {
      let start = data.length - this.state.cSpread - 3;
      let sum = 0;
      let newCases = 0;
      for (start; start < data.length - 3; start++) {
        let ppd = data[start].R / this.state.cSpread;
        newCases = +data[start].newCases * ppd;
        if (newCases < 0) {
          newCases = 0;
        }
        sum = sum + newCases;
      }
      if (
        this.state.indefiniteImmunity &&
        +data[data.length - 1].positive - removed > +maxCases
      ) {
        sum = +maxCases - (+data[data.length - 1].positive - removed);
      }
      if (sum < 0) {
        sum = 0;
      }
      newCases = Math.round(sum);
      sum = Math.round(sum + +data[data.length - 1].positive);
      let nDate = new Date(data[data.length - 1].dateChecked);
      let R = rValue;
      if (!this.state.indefiniteImmunity && start > immunityLength) {
        removed = removed - data[start - immunityLength].newCases;
        if (immunityLength === 0) {
          removed = sum * cfr;
        }
      }
      removed = removed + newCases;
      R = R * ((+maxCases - removed) / +maxCases || 0);
      R = R > 0 ? R : 0;
      if (hospitalizedCurrently.length === 14) {
        hospitalizedCurrently.pop();
      }
      hospitalizedCurrently.unshift(newCases);
      data = [
        ...data,
        {
          color: color,
          x: new Date(nDate.setDate(nDate.getDate() + 1)),
          dateChecked: this.dateToYMD(
            new Date(nDate.setDate(nDate.getDate() + 1))
          ),
          positive: sum,
          confirmed: this.state.cumulativeSwitch ? +sum : +newCases,
          newCases,
          R,
          Rifr: R,
          death: Math.round(
            this.state.cumulativeSwitch
              ? +data[data.length - 10].newCases * cfr +
              +data[data.length - 1].totaldeath
              : +data[data.length - 10].newCases * cfr
          ),
          totaldeath:
            +data[data.length - 10].newCases * cfr +
            +data[data.length - 1].totaldeath,
          totalTestResults: data.totalTestResults,
          hospitalizedCumulative: Math.round(sum * hospitalizationRate),
          inIcuCumulative: Math.round(sum * icuRate),
          onVentilatorCumulative: Math.round(sum * ventilatorRate),
        },
      ];
      ++c;
    }
    return data;
  }

  calcProjections() {
    let {
      data,
      tCFR,
      cfr,
      RReal,
      removed,
      immunityLength,
      hospitalizationRate,
      icuRate,
      ventilatorRate,
    } = this.convertData();
    let recentDay = data[data.length - 1];
    recentDay.newHospitalized =
      recentDay.hospitalizedCumulative -
      data[data.length - 2].hospitalizedCumulative;
    let pastData = [...data].slice(
      data.length - this.state.cSpread - 40,
      data.length - 30
    );
    let maxCases;
    if (this.state.country === "United States") {
      if (this.state.county === "All") {
        maxCases = this.state.excludeIFR
          ? statePop[this.state.state]
          : statePop[this.state.state] /
          ((cfr * 100) / this.state.estimatedIFR);
      } else {
        maxCases = this.state.excludeIFR
          ? countyPopulations[this.state.state][this.state.county]
          : countyPopulations[this.state.state][this.state.county] /
          ((cfr * 100) / this.state.estimatedIFR);
      }
    } else {
      maxCases =
        this.state.population / ((cfr * 100) / this.state.estimatedIFR);
    }

    let past = this.estimatedPast(pastData, data, cfr, maxCases, removed);
    let hospitalizedCurrently = past.hospitalizedCurrently;
    data = past.data;
    // if (data.length > 0) {
    //   //cfr = +data[data.length - 1].totaldeath / +data[data.length - 1].positive;
    //   hospitalizationRate =
    //     +data[data.length - 1].hospitalizedCumulative /
    //     +data[data.length - 1].positive;
    //   icuRate =
    //     +data[data.length - 1].inIcuCumulative /
    //     +data[data.length - 1].positive;
    //   ventilatorRate =
    //     +data[data.length - 1].onVentilatorCumulative /
    //     +data[data.length - 1].positive;
    // }
    if (this.state.project) {
      data = this.projectData(
        data,
        this.state.DayTO,
        removed,
        maxCases,
        immunityLength,
        cfr,
        this.state.RValueEstimated,
        "#82ca9d",
        hospitalizationRate,
        icuRate,
        ventilatorRate,
        hospitalizedCurrently
      );
      data = this.projectData(
        data,
        this.state.dayProjection,
        removed,
        maxCases,
        immunityLength,
        cfr,
        this.state.RValueProjection,
        "#a83238",
        hospitalizationRate,
        icuRate,
        ventilatorRate,
        hospitalizedCurrently
      );
      data = this.projectData(
        data,
        this.state.DayPP,
        removed,
        maxCases,
        immunityLength,
        cfr,
        this.state.RPVProjection,
        "#c4b102",
        hospitalizationRate,
        icuRate,
        ventilatorRate,
        hospitalizedCurrently
      );
    }
    this.setState({
      calculatedData: data,
      tRReal: RReal,
      cfr,
      tCFR,
      recentDay,
    });
  }

  render() {
    let { cfr } = this.state;
    this.updateURLParams();
    let data = this.state.calculatedData;
    if (!this.state.project) {
      data = data.filter(
        (v) => v.x >= this.state.startDate && v.x <= this.state.endDate
      );
    }
    if (
      this.state.activePage === "Testing" ||
      (this.state.activePage === "Hospital Usage" &&
        !this.state.cumulativeSwitch)
    ) {
      data = data.filter((data) => new Date(data.dateChecked) <= new Date());
    }
    if (
      this.state.activePage === "Vaccinations"
    ) {
      data = data.filter((data) => +data.vaccinesDistributed >= 0);
    }
    let title = "";
    if (this.state.county && this.state.county !== "All") {
      title = this.state.state + ` (${this.state.county})`;
    } else if (this.state.state && this.state.state !== "All") {
      title = this.state.country + ", " + this.state.state;
    } else {
      title = this.state.country;
    }
    let rangeData = [];
    if (this.state.activePage === "Estimated Infections") {
      data = data.filter((data) => data.IFRDeaths);
      rangeData = data.map((v) => ({
        day: v.x,
        estimatedCaseRange: v.IFRDeaths,
        average: v.average,
        confirmed: v.confirmed,
        lowerIFRPop: v.lowerIFRPop,
        upperIFRPop: v.upperIFRPop,
      }));
    }

    // let dates = this.generateDates(new Date(2020, '00', 23), new Date());
    // let maskDates = {};
    // Object.keys(closures).forEach(v=>{
    //   if(closures[v].stateMaskOrder){
    //     let date = this.getFormattedDate(new Date(closures[v].stateMaskOrder));
    //     if(!maskDates[date]){
    //       maskDates[date]= 0;
    //     }
    //     maskDates[date]+= statePop[v];
    //   }
    // })
    // console.log(maskDates);
    // let maskPop = 0;
    // let totalPop = 319762353;
    // dates.forEach(d=>{
    //   let date = this.getFormattedDate(d);
    //   if(maskDates[date]){
    //     maskPop+=maskDates[date];
    //   }
    //   console.log(d.toString(), maskPop*100/totalPop);
    // })
    return (
      <>
        {/* <header>
          <NavBar active={this.state.activePage} onClick={(e)=>this.changePage(e)} />
        </header> */}
        <h1
          style={{ textAlign: "center", marginBottom: "5px", marginTop: "5px" }}
        >
          COVID-19 Data
        </h1>
        {this.state.activeMainPage === "Graphs" ? (
          <h3 style={{ textAlign: "center", marginTop: "0px" }}>
            {title} {this.state.cumulativeSwitch ? "Cumulative" : "Daily"}{" "}
            {this.state.activePage}
          </h3>
        ) : (
          <h3 style={{ textAlign: "center", marginTop: "0px" }}>{"     "}</h3>
        )}
        <ReactLoadingOverlay
          active={this.state.data.length === 0}
          spinner
          text="Loading COVID data..."
          styles={{
            overlay: (base) => {
              return {
                ...base,
                display: "flex",
                background: "#75b9894d",
              };
            },
            spinner: (base) => {
              return {
                ...base,
                marginTop: "-500px",
                width: "100px",
                "& svg circle": {
                  stroke: "#75b989",
                },
              };
            },
          }}
        >
          <NavBar
            active={this.state.activeMainPage}
            onClick={(e) => this.changeMainPage(e)}
            country={this.state.country}
            county={this.state.county}
          />
          {this.state.activeMainPage === "Maps" &&
            this.state.data.length !== 0 ? (
            <ReactLoadingOverlay
              active={!this.state.fips && this.state.data.length !== 0}
              spinner
              text="Loading Map..."
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "#75b9894d",
                }),
              }}
            >
              <Map
                fips={this.state.fips}
                globalFips={this.state.globalFips}
                stateFips={this.state.stateFips}
                setTooltipContent={(d) => this.setContent(d)}
              />
            </ReactLoadingOverlay>
          ) : this.state.activeMainPage === "Tables" ? (
            <ReactLoadingOverlay
              active={
                !this.state.fips ||
                !this.state.globalFips ||
                !this.state.stateFips
              }
              spinner
              text="Loading Tables..."
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "#75b9894d",
                }),
              }}
            >
              {this.state.globalFips && (
                <TableView
                  data={this.state.globalFips}
                  fips={this.state.fips}
                  globalFips={this.state.globalFips}
                  stateFips={this.state.stateFips}
                  instance
                  setTooltipContent={(d) => this.setContent(d)}
                  onCountyChange={(l) => this.onCountyChange(l)}
                  onStateChange={(l) => this.onStateChange(l)}
                  onCountryChange={(l) => this.onCountryChange(l)}
                  updateMainView={(e) => this.changeMainPage(e)}
                />
              )}
            </ReactLoadingOverlay>
          ) : (
            <>
              <GraphViewBar
                active={this.state.activePage}
                onClick={(e) => this.changePage(e)}
                country={this.state.country}
                county={this.state.county}
              />

              <ResponsiveContainer
                width="100%"
                height={600}
                styles={{ color: "white important!" }}
              >
                <ComposedChart
                  data={
                    this.state.activePage === "Estimated Infections"
                      ? rangeData
                      : data
                  }
                  margin={{ top: 20, right: 50, bottom: 0, left: 50 }}
                >
                  {/* {
              Object.values(stateABRMap).map(v=>
                <Line
                  name={`${!this.state.cumulativeSwitch ? 'Daily ' : ""}Cases`}
                  type="monotone"
                  dataKey={!this.state.cumulativeSwitch ?  `${v}_cases_daily`: `${v}_cases`}
                  stroke="#4a4a4a"
                  key= {v.state}
                  activeDot={{ r: 8 }}
                  dot={<LineDot />}
                />
              )
            } */}
                  {this.state.activePage === "Estimated Infections" && (
                    <Area
                      name={`Estimated Infection Range`}
                      dataKey="estimatedCaseRange"
                      stroke="#f90c"
                      fill="#ffc269d9"
                    />
                  )}
                  {this.state.activePage === "Estimated Infections" && (
                    <Line
                      name={`${!this.state.cumulativeSwitch ? "Daily " : ""
                        } Confirmed Cases`}
                      type="monotone"
                      dataKey="confirmed"
                      stroke="rgb(116 185 137)"
                      fill="#75b989"
                      fillOpacity={0.3}
                      activeDot={{ r: 8 }}
                    />
                  )}
                  {this.state.activePage === "Estimated Infections" && (
                    <Line
                      name={`Average Daily Confirmed Cases`}
                      type="monotone"
                      dataKey="average"
                      stroke="rgb(116 185 137)"
                      fill="#75b989"
                      fillOpacity={0.3}
                      active
                      Dot={{ r: 8 }}
                    />
                  )}{" "}
                  {this.state.activePage === "Estimated Infections" && (
                    <Line
                      name={`Lower bound attack rate`}
                      type="monotone"
                      dataKey="lowerIFRPop"
                      stroke="#8884d8"
                      fill="#75b989"
                      fillOpacity={0.3}
                      activeDot={{ r: 8 }}
                    />
                  )}
                  {this.state.activePage === "Estimated Infections" && (
                    <Line
                      name={`Upper bound attack rate`}
                      type="monotone"
                      dataKey="upperIFRPop"
                      stroke="#8884d8"
                      fill="#75b989"
                      fillOpacity={0.3}
                      activeDot={{ r: 8 }}
                    />
                  )}
                  {this.state.activePage === "Cases" && (
                    <Area
                      name={`${!this.state.cumulativeSwitch ? "Daily " : ""
                        }Cases`}
                      type="monotone"
                      dataKey="confirmed"
                      stroke="rgba(116, 185, 137, 0.64)"
                      fill="#75b989"
                      fillOpacity={0.3}
                      activeDot={{ r: 8 }}
                      isAnimationActive={false}
                      dot={<LineDot />}
                    />
                  )}
                  {this.state.activePage === "Cases" &&
                    !this.state.cumulativeSwitch && (
                      <Area
                        name={`7 Day Average Daily Cases`}
                        type="monotone"
                        dataKey="average"
                        stroke="#8884d8"
                        strokeWidth="2px"
                        fill="#75b989"
                        fillOpacity={0.05}
                        isAnimationActive={false}
                        dot={null}
                      />
                    )}
                  {this.state.activePage === "Cases" && this.state.project && (
                    <Line
                      name={`Projected ${!this.state.cumulativeSwitch ? "Daily cases" : "Cases"
                        } based on effective R`}
                      type="monotone"
                      dataKey="pestimated"
                      stroke="#b7b6b6"
                      fill="#b7b6b6"
                      isAnimationActive={false}
                      activeDot={{ r: 8 }}
                    />
                  )}
                  {this.state.activePage === "Deaths" &&
                    !this.state.cumulativeSwitch && (
                      <Area
                        name={`7 Day Average Daily Deaths`}
                        type="monotone"
                        dataKey="averageDeath"
                        stroke="#a83238"
                        fill="#a83238"
                        strokeWidth="2px"
                        fillOpacity={0.05}
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {(this.state.activePage === "Cases" ||
                    this.state.activePage === "Deaths") && (
                      <Area
                        name={`${!this.state.cumulativeSwitch ? "Daily " : ""
                          }Deaths`}
                        type="monotone"
                        dataKey="death"
                        stroke="#a83238"
                        fill="#a83238"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                        dot={<LineDot color={"#a83238"} />}
                      />
                    )}
                  {(this.state.activePage === "Cases" ||
                    this.state.activePage === "Deaths") &&
                    this.state.project && (
                      <Area
                        name={`Projected ${!this.state.cumulativeSwitch
                          ? "Daily deaths"
                          : "Deaths"
                          } based on effective R`}
                        type="monotone"
                        dataKey="ppdeath"
                        stroke="#581f22"
                        fill="#581f22"
                        fillOpacity={0.5}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {/* {!this.state.cumulativeSwitch &&
              this.state.activePage === "Hospital Usage" && (
                <Line
                  name={`Change in Hospitalization`}
                  type="monotone"
                  dataKey="hospitalizedIncrease"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              )} */}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.inIcuCurrently > 0) && (
                      <Area
                        name={`Icu Currently`}
                        type="monotone"
                        dataKey="inIcuCurrently"
                        stroke="#58a5f0"
                        fill="#58a5f0"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.inIcuCumulative > 0) && (
                      <Area
                        name={`Icu Cumulative`}
                        type="monotone"
                        dataKey="inIcuCumulative"
                        stroke="#004c8c"
                        fill="#004c8c"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.hospitalizedCurrently > 0) && (
                      <Area
                        name={`Hospitalized Currently`}
                        type="monotone"
                        dataKey="hospitalizedCurrently"
                        stroke="#ff5f52"
                        fill="#ff5f52"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.hospitalizedCumulative > 0) && (
                      <Area
                        name={`Hospitalized Cumulative`}
                        type="monotone"
                        dataKey="hospitalizedCumulative"
                        stroke="#8e0000"
                        fill="#8e0000"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.onVentilatorCumulative > 0) && (
                      <Area
                        name={`On Ventilators Cumulative`}
                        type="monotone"
                        dataKey="onVentilatorCumulative"
                        stroke="#38006b"
                        fill="#38006b"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.activePage === "Hospital Usage" &&
                    data.some((v) => v.onVentilatorCurrently > 0) && (
                      <Area
                        name={`On Ventilator Currently`}
                        type="monotone"
                        dataKey="onVentilatorCurrently"
                        stroke="#9c4dcc"
                        fill="#9c4dcc"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {(this.state.activePage === "Hospital Usage" ||
                    this.state.activePage === "Cases") && (
                      <Area
                        name="Estimated R Value"
                        type="monotone"
                        dataKey="Rifr"
                        stroke="#ff9900"
                        fill="#ff9900"
                        fillOpacity={0.2}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`New Tests`}
                        type="monotone"
                        dataKey="tests"
                        stroke="#8884d8"
                        fill="#8884d8"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`7 day New Test average`}
                        type="monotone"
                        dataKey="averageTest"
                        stroke="#8884d8"
                        strokeWidth="2px"
                        isAnimationActive={false}
                        fillOpacity={0.05}
                        activeDot={{ r: 8 }}
                        dot={null}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`New Positives`}
                        type="monotone"
                        dataKey="confirmed"
                        stroke="#a83238"
                        fill="#a83238"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`Cumulative Tests`}
                        type="monotone"
                        dataKey="cTests"
                        stroke="#8884d8"
                        fill="#8884d8"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`Cumulative Positives`}
                        type="monotone"
                        dataKey="cPos"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`Percentage Positive`}
                        type="monotone"
                        dataKey="testP"
                        stroke="#82df9d"
                        fill="#82df9d"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                        yAxisId="right"
                      />
                    )}
                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`Total Vaccinations Completed`}
                        type="monotone"
                        dataKey="vaccinationsCompleted"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`New Vaccinations Completed`}
                        type="monotone"
                        dataKey="newVaccinationsCompleted"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`Total Vaccinations Initiated`}
                        type="monotone"
                        dataKey="vaccinationsInitiated"
                        stroke="#ff9900"
                        fill="#ff9900"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`New Vaccinations Initiated`}
                        type="monotone"
                        dataKey="newVaccinationsInitiated"
                        stroke="#ff9900"
                        fill="#ff9900"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}

                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`Total Vaccinations Distributed`}
                        type="monotone"
                        dataKey="vaccinesDistributed"
                        stroke="#ff0000"
                        fill="#ff0000"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {!this.state.cumulativeSwitch &&
                    this.state.activePage === "Vaccinations" && (
                      <Line
                        name={`New Vaccinations Distributed`}
                        type="monotone"
                        dataKey="newVaccinationsDistributed"
                        stroke="#ff0000"
                        fill="#ff0000"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                      />
                    )}
                  {this.state.cumulativeSwitch &&
                    this.state.activePage === "Testing" && (
                      <Line
                        name={`Cumulative Percentage Positive`}
                        type="monotone"
                        dataKey="testPT"
                        stroke="#581f22"
                        fill="#82df9d"
                        isAnimationActive={false}
                        activeDot={{ r: 8 }}
                        yAxisId="right"
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].stateClosure != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].stateClosure)
                          )
                        )}
                        stroke="green"
                        strokeWidth={2}
                        label={{
                          position: "top",
                          value: closures[this.state.state].closureLabel,
                          fill: "green",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].stateMaskOrder != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].stateMaskOrder)
                          )
                        )}
                        stroke="#ff9900"
                        strokeWidth={2}
                        label={{
                          position: "top",
                          value: closures[this.state.state].maskOrderLabel,
                          fill: "#ff9900",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].stateReopening != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].stateReopening)
                          )
                        )}
                        stroke="Red"
                        strokeWidth={2}
                        label={{
                          position: "insideTop",
                          value: closures[this.state.state].openingLabel,
                          fill: "Red",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].max != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].max)
                          )
                        )}
                        stroke="green"
                        strokeWidth={2}
                        label={{
                          position: "top",
                          value: closures[this.state.state].maxLabel,
                          fill: "#205d1d",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].max != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(2020, "06", 15)
                          )
                        )}
                        stroke="#ff9900"
                        strokeWidth={2}
                        label={{
                          position: "top",
                          value: "Shift to White House Reporting",
                          fill: "#ff9900",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].reopening != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].reopening)
                          )
                        )}
                        stroke="red"
                        strokeWidth={2}
                        label={{
                          position: "insideTop",
                          value: closures[this.state.state].reopeningLabel,
                          fill: "#9c2722",
                          fontSize: 12,
                        }}
                      />
                    )}
                  {closures[this.state.state] &&
                    this.state.country === "United States" &&
                    closures[this.state.state].stateReopened != null &&
                    data[0] && (
                      <ReferenceLine
                        x={Math.round(
                          this.getDateDifference(
                            new Date(data[0].dateChecked),
                            new Date(closures[this.state.state].stateReopened)
                          )
                        )}
                        stroke="red"
                        strokeWidth={2}
                        label={{
                          position: "top",
                          value: closures[this.state.state].reopenedLabel,
                          fill: "#9c2722",
                          fontSize: 12,
                        }}
                      />
                    )}
                  <Tooltip
                    itemSorter={(item) => item.name}
                    formatter={(value, name, props) => {
                      if (name === "Estimated Infection Range") {
                        let estimatedCaseRange =
                          props.payload.estimatedCaseRange;
                        return (
                          estimatedCaseRange[0].toLocaleString() +
                          " - " +
                          estimatedCaseRange[1].toLocaleString()
                        );
                      } else if (
                        name === "Lower bound attack rate" ||
                        name === "Upper bound attack rate"
                      ) {
                        return value.toLocaleString() + "% of population";
                      } else {
                        return value.toLocaleString();
                      }
                    }}
                    labelFormatter={(v) => {
                      let d = new Date(data[v] ? data[v].dateChecked : "");
                      let d2 = new Date(this.state.maxDate);
                      d2 = new Date(
                        d2.setMinutes(d2.getMinutes() + d2.getTimezoneOffset())
                      );

                      return (
                        <span style={{ color: "black" }}>
                          {d > d2 && <>{"Projection For: "}</>}
                          {this.getFormattedDate(d)}
                        </span>
                      );
                    }}
                  />
                  <XAxis
                    tick={{ fill: "#fff" }}
                    tickCount={5}
                    tickFormatter={(tick) => {
                      let d = new Date(
                        data[tick] ? data[tick].dateChecked : ""
                      );
                      return this.getFormattedDate(d);
                    }}
                  />
                  <YAxis
                    range={[0, "auto"]}
                    domain={[0, "auto"]}
                    type="number"
                    allowDataOverflow={true}
                    tick={{ fill: "#fff" }}
                    tickFormatter={(tick) => tick.toLocaleString()}
                  />
                  {this.state.activePage === "Testing" && (
                    <YAxis
                      tick={{ fill: "#fff" }}
                      yAxisId="right"
                      orientation="right"
                      tickLine={false}
                      axisLine={false}
                      domain={[0, 100]}
                      range={[0, 100]}
                      type="number"
                      allowDataOverflow={true}
                    >
                      <Label
                        angle={90}
                        value="Percentage Positive"
                        position="right"
                        offset={20}
                        style={{
                          textAnchor: "middle",
                          paddingLeft: "100px",
                          fill: "white",
                        }}
                      />
                    </YAxis>
                  )}
                  <CartesianGrid stroke="white" />
                  <Legend iconType="rect" />
                </ComposedChart>
              </ResponsiveContainer>

              <br />
              <DataBar props={this.state} />
              {this.state.county !== "All" && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "20px",
                      marginTop: "-5px",
                      color: "#6483dd",
                    }}
                  >
                    *County level calculations do not account for changes in
                    testing*
                  </div>
                </>
              )}
              {this.state.activePage === "Estimated Infections" && (
                <>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      marginBottom: "20px",
                      marginTop: "-5px",
                      color: "#6483dd",
                    }}
                  >
                    *Due to delay from infection to death estimates are delayed
                    21 days*
                  </div>
                </>
              )}
              <div
                style={{
                  textAlign: "left important!",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <label style={{ marginRight: "0px", marginLeft: "20px" }}>
                  <span style={{ marginRight: "10px" }}>Country:</span>
                  <div
                    style={{
                      color: "black",
                      borderRadius: "10px",
                      display: "inline-block",
                      marginRight: "0px",
                      marginBottom: "40px",
                      height: "8px",
                      minWidth: "250px",
                      maxWidth: "350px",
                      width: "30vw",
                    }}
                  >
                    <Select
                      isSearchable
                      defaultValue={"United States"}
                      value={{
                        label: this.state.country,
                        value: this.state.country,
                      }}
                      onChange={async (e) => {
                        this.setState({ country: e.value, data: [] });
                        this.onCountryChange(e.value);
                      }}
                      options={[
                        { value: "All", label: "All" },
                        ...Object.keys(this.state.countries)
                          .filter((v) => v !== "All")
                          .sort()
                          .map((s) => ({ value: s, label: s })),
                      ]}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </label>
              </div>
              {this.state.country === "United States" && (
                <div
                  style={{
                    textAlign: "left important!",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <label style={{ marginRight: "0px", marginLeft: "20px" }}>
                    <span style={{ marginRight: "29px" }}>State:</span>
                    <div
                      style={{
                        color: "black",
                        borderRadius: "10px",
                        display: "inline-block",
                        marginRight: "0px",
                        marginBottom: "40px",
                        height: "8px",
                        minWidth: "250px",
                        maxWidth: "350px",
                        width: "30vw",
                      }}
                    >
                      <Select
                        isSearchable
                        defaultValue={this.state.data[0]}
                        value={{
                          label: this.state.state,
                          value: this.state.state,
                        }}
                        onChange={async (e) => {
                          this.setState({ data: [] });
                          this.onStateChange(e.value);
                        }}
                        options={this.state.states.map((s) => ({
                          value: s,
                          label: s,
                        }))}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  </label>
                </div>
              )}
              {this.state.country === "United States" &&
                this.state.state !== "All" &&
                this.state.state !== "All (Exclude NY and NJ)" && (
                  <div
                    style={{
                      textAlign: "left important!",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <label style={{ marginRight: "0px", marginLeft: "20px" }}>
                      <span style={{ marginRight: "14px" }}>County:</span>
                      <div
                        style={{
                          color: "black",
                          borderRadius: "10px",
                          display: "inline-block",
                          marginRight: "0px",
                          marginBottom: "40px",
                          height: "8px",
                          minWidth: "250px",
                          maxWidth: "350px",
                          width: "30vw",
                        }}
                      >
                        <ReactLoadingOverlay
                          active={
                            this.state.data.length !== 0 &&
                            !this.state.counties[this.state.state]
                          }
                          text="Loading..."
                          styles={{
                            zIndex: "1",
                            overlay: (base) => ({
                              ...base,
                              zIndex: "1",
                              background: "rgba(113, 188, 135, 0.23)",
                            }),
                          }}
                        >
                          <Select
                            isSearchable
                            disabled={this.state.counties[this.state.state]}
                            defaultValue={"All"}
                            value={{
                              label: this.state.county,
                              value: this.state.county,
                            }}
                            onChange={async (e) => {
                              this.setState({ county: e.value, data: [] });
                              this.onCountyChangeV1(e.value);
                            }}
                            options={
                              this.state.counties[this.state.state]
                                ? [
                                  { label: "All", value: "All" },
                                  ...Object.keys(
                                    this.state.counties[this.state.state]
                                  )
                                    .sort()
                                    .map((s) => ({ value: s, label: s })),
                                ]
                                : []
                            }
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                            menuPortalTarget={document.body}
                          />
                        </ReactLoadingOverlay>
                      </div>
                    </label>
                  </div>
                )}
              {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <span
            title="Based on available data"
            style={{
              textAlign: "left",
              marginTop: "10px",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            {this.state.state === "United States" ? "The " : ""}
            {this.state.state}'s total case fatality rate (CFR):{" "}
            {(tCFR * 100).toFixed(2)}%
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <span
            title="Based on available data"
            style={{
              textAlign: "left",
              marginTop: "10px",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          >
            {this.state.state === "United States" ? "The " : ""}
            {this.state.state}'s recent case fatality rate (CFR):{" "}
            {(cfr * 100).toFixed(2)}%
          </span>
        </div>
        {RReal > 0 && RReal < 10 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <span
              title="Accounts for changes in testing"
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              {this.state.state === "United States" ? "The " : ""}
              {this.state.state}'s estimated (R) value for past 30 days: {RReal}
            </span>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <span
              title="Locations with small data will be less accurate"
              style={{
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              {this.state.state === "United States" ? "The " : ""}
              {this.state.state}'s data does not allow for projections due to
              inaccuracies or small sample size
            </span>
          </div>
        )} */}
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <label style={{ marginRight: "20px", marginLeft: "20px" }}>
                  <span style={{ marginRight: "10px" }}>View New Cases</span>
                  <Switch
                    name="switch"
                    checkedIcon={null}
                    offColor="#058702"
                    onColor="#868786"
                    uncheckedIcon={null}
                    onChange={() => this.handleSwitch()}
                    checked={this.state.cumulativeSwitch}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    View Cumulative Cases
                  </span>
                </label>
              </div>
              <br />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                <label style={{ marginRight: "20px", marginLeft: "20px" }}>
                  <span style={{ marginRight: "10px" }}>View Data</span>
                  <Switch
                    name="switch"
                    disabled={this.state.activePage === "Estimated Infections"}
                    checkedIcon={null}
                    offColor="#058702"
                    onColor="#868786"
                    uncheckedIcon={null}
                    onChange={async () => {
                      await this.setState({
                        project: !this.state.project,
                      });
                      this.calcProjections();
                    }}
                    checked={this.state.project}
                  />
                  <span style={{ marginLeft: "10px" }}>Project Data</span>
                </label>
              </div>
              {!this.state.project ? (
                <>
                  <br />
                  <div
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(250px, 0fr))",
                      alignItems: "left",
                      justifyItems: "left",
                      marginBottom: "15px",
                    }}
                  >
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      From:
                      <div
                        style={{
                          paddingLeft: "10px",
                          marginRight: "10px",
                        }}
                      >
                        <DatePicker
                          disabled={this.state.project}
                          selected={this.state.startDate}
                          onChange={(date) => this.setStartDate(date)}
                          minDate={this.state.min}
                          maxDate={this.state.max}
                          selectsStart
                          showYearDropdown
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </div>
                      <br />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      To:
                      <div
                        style={{
                          paddingLeft: "30px",
                          marginRight: "10px",
                        }}
                      >
                        <DatePicker
                          disabled={this.state.project}
                          selected={this.state.endDate}
                          onChange={(date) => this.setEndDate(date)}
                          minDate={this.state.min}
                          maxDate={this.state.max}
                          selectsEnd
                          showYearDropdown
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                        />
                      </div>
                      {this.state.dateRangeChanged && (
                        <button
                          type="button"
                          onClick={() => this.resetDates()}
                          className="close"
                        >
                          X
                        </button>
                      )}
                      <br />
                    </div>
                  </div>

                  {this.state.activePage === "Estimated Infections" && (
                    <div
                      style={{
                        marginLeft: "0px",
                        marginRight: "0px",
                        display: "grid",
                        gridTemplateColumns:
                          "repeat(auto-fit, minmax(250px, 0fr))",
                        alignItems: "left",
                        justifyItems: "left",
                        marginBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <label htmlFor="R" style={{ marginRight: "5px" }}>
                          Lower IFR estimate:{" "}
                        </label>
                        <input
                          style={{ width: "40px", textAlign: "right" }}
                          type="number"
                          max={(cfr * 100).toFixed(2)}
                          step="0.01"
                          name="estimatedIFR"
                          value={this.state.lowerIfR}
                          onChange={async (e) => {
                            await this.setState({ lowerIfR: e.target.value });
                            this.calcProjections();
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <label htmlFor="R" style={{ marginRight: "5px" }}>
                          Upper IFR estimate:{" "}
                        </label>
                        <input
                          style={{ width: "40px", textAlign: "right" }}
                          type="number"
                          max={(cfr * 100).toFixed(2)}
                          step="0.01"
                          name="estimatedIFR"
                          value={this.state.upperIFR}
                          onChange={async (e) => {
                            await this.setState({ upperIFR: e.target.value });
                            this.calcProjections();
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      textAlign: "left",
                      fontSize: "14px",
                      marginBottom: "20px",
                      marginLeft: "20px",
                      marginTop: "-5px",
                      color: "#6483dd",
                    }}
                  >
                    *IFR Ranges will vary depending on demographics and medical
                    care*
                  </div>
                </>
              ) : (
                <>
                  <h3
                    style={{
                      textAlign: "left",
                      marginRight: "20px",
                      marginLeft: "20px",
                    }}
                  >
                    COVID Estimates
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <label style={{ marginRight: "20px", marginLeft: "20px" }}>
                      <span style={{ marginRight: "10px" }}>
                        Include estimated infection fatality rate
                      </span>
                      <Switch
                        name="switch"
                        checkedIcon={null}
                        offColor="#058702"
                        onColor="#868786"
                        uncheckedIcon={null}
                        onChange={async () => {
                          await this.setState({
                            excludeIFR: !this.state.excludeIFR,
                          });
                          this.calcProjections();
                        }}
                        checked={this.state.excludeIFR}
                      />
                      <span style={{ marginLeft: "10px" }}>
                        Exclude estimated infection fatality rate
                      </span>
                    </label>
                  </div>
                  <br />
                  {!this.state.excludeIFR && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <label htmlFor="R" style={{ marginRight: "5px" }}>
                          Estimated Infection Fatality Rate (IFR):{" "}
                        </label>
                        <input
                          style={{ width: "40px", textAlign: "right" }}
                          type="number"
                          max={(cfr * 100).toFixed(2)}
                          step="0.01"
                          name="estimatedIFR"
                          value={this.state.estimatedIFR}
                          onChange={(e) => this.handleChange(e)}
                        />
                        %
                      </div>
                      <br />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <label style={{ marginRight: "20px", marginLeft: "20px" }}>
                      <span style={{ marginRight: "10px" }}>
                        Infection provides waning Immunity
                      </span>
                      <Switch
                        name="switch"
                        checkedIcon={null}
                        offColor="#058702"
                        onColor="#868786"
                        uncheckedIcon={null}
                        onChange={async () => {
                          await this.setState({
                            indefiniteImmunity: !this.state.indefiniteImmunity,
                          });
                          this.calcProjections();
                        }}
                        checked={this.state.indefiniteImmunity}
                      />
                      <span style={{ marginLeft: "10px" }}>
                        Infection provides indefinite immunity
                      </span>
                    </label>
                  </div>
                  <br />
                  {!this.state.indefiniteImmunity && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          marginRight: "20px",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        <label htmlFor="R" style={{ marginRight: "5px" }}>
                          Estimated length of immunity:{" "}
                        </label>
                        <input
                          style={{
                            width: "40px",
                            textAlign: "right",
                            marginRight: "5px",
                          }}
                          type="number"
                          step="0.1"
                          min=".1"
                          name="immunityLength"
                          value={this.state.immunityLength}
                          onChange={(e) => this.handleChange(e)}
                        />
                        years
                      </div>
                      <br />
                    </div>
                  )}
                  <h3
                    style={{
                      textAlign: "left",
                      marginRight: "20px",
                      marginLeft: "20px",
                      textDecorationColor: "#82ca9d",
                      textDecorationLine: "underline",
                    }}
                  >
                    Current Behavior
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <label htmlFor="R" style={{ marginRight: "5px" }}>
                        Days to Maintain Current Behavior:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="DayTO"
                        value={this.state.DayTO}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div style={{ marginRight: "20px", marginLeft: "20px" }}>
                      <label
                        htmlFor="RValueEstimated"
                        style={{ marginRight: "5px" }}
                      >
                        Estimated effective R Value:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="RValueEstimated"
                        value={this.state.RValueEstimated}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <br />
                    <br />
                  </div>
                  <h3
                    style={{
                      textAlign: "left",
                      marginRight: "20px",
                      marginLeft: "20px",
                      textDecorationColor: "#a83238",
                      textDecorationLine: "underline",
                    }}
                  >
                    Change in Behavior #1
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <label
                        htmlFor="dayProjection"
                        style={{ marginRight: "5px" }}
                      >
                        Days to Model Post Opening:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="dayProjection"
                        value={this.state.dayProjection}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <label htmlFor="R" style={{ marginRight: "5px" }}>
                        Estimated effective R Value:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="RValueProjection"
                        value={this.state.RValueProjection}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    {/* <div style= {{ marginRight: '20px', marginBottom: '10px'}}>
       <label htmlFor="spread">How long is someone spreading</label>
      <input style={{width: '150px'}} type="number"name= "spread" label= "How long is someone spreading" value={this.state.spread} onChange={(e)=>this.handleChange(e)}/>
    </div> */}
                  </div>
                  <h3
                    style={{
                      textAlign: "left",
                      marginRight: "20px",
                      marginLeft: "20px",
                      textDecorationColor: "#c4b102",
                      textDecorationLine: "underline",
                    }}
                  >
                    {" "}
                    Change in Behavior #2
                  </h3>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      flexWrap: "wrap",
                      marginBottom: "50px",
                    }}
                  >
                    <div
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <label htmlFor="DayPP" style={{ marginRight: "5px" }}>
                        Days to Model Post Re-closure:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="DayPP"
                        value={this.state.DayPP}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div
                      style={{
                        marginRight: "20px",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      <label
                        htmlFor="RPVProjection"
                        style={{ marginRight: "5px" }}
                      >
                        Estimated effective R Value:{" "}
                      </label>
                      <input
                        style={{ width: "60px" }}
                        type="number"
                        name="RPVProjection"
                        value={this.state.RPVProjection}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {/* <div style= {{ marginRight: '20px', marginBottom: '10px'}}>
       <label htmlFor="recloseSpread">How long is someone spreading</label>
      <input style={{width: '150px'}} type="number"name= "recloseSpread" label= "How long is someone spreading" value={this.state.recloseSpread} onChange={(e)=>this.handleChange(e)}/>
    </div> */}
          <>
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                marginBottom: "10px",
                marginTop: "50px",
                color: "#6483dd",
              }}
            >
              <a
                style={{
                  color: "#6483dd",
                }}
                href="mailto:elispiegelcovidprojection@gmail.com"
              >
                Report an issue or share an idea
              </a>
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "12px",
                marginBottom: "10px",
              }}
            >
              {"Data from "}
              <a
                style={{
                  color: "#6483dd",
                }}
                href="https://github.com/nytimes/covid-19-data"
              >
                The New York Times
              </a>
              {" and "}
              <a
                style={{
                  color: "#6483dd",
                }}
                href="https://covidactnow.org/"
              >
                <img
                  style={{
                    height: "10px",
                  }}
                  src={covidActNowLogo}
                  alt="Covid Act Now Logo" />
              </a>
            </div>
          </>
        </ReactLoadingOverlay>
      </>
    );
  }
}

export default App;
